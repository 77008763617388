@import '~bulma/sass/utilities/mixins';
@import '../../../css/themes/matchmade/variables';
@import './common';

$thin-border: thin solid $semi-light-grey;

.VideoTab {
  background-color: $white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;

  &__copy-tracking-url i.material-icons {
    font-size: 1rem;
  }

  &__qr-code-link > img {
    border: thin solid;
  }

  h2,
  a:not(.VideoTab__download-qr-code) {
    font-size: 1.25rem;
  }

  &__title {
    width: 100%;
    height: 5.2rem;
    padding: 1.1rem 2rem 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: $thin-border;
  }

  &__content {
    width: 100%;
    display: flex;
    @include until($mobile-break-point) {
      flex-wrap: wrap;
    }
    align-items: stretch;
    justify-content: center;
    flex: 1;

    > div:not(:last-child) {
      @include from($mobile-break-point) {
        border-right: $thin-border;
      }
      @include until($mobile-break-point) {
        border-bottom: $thin-border;
      }
    }
    @include until($mobile-break-point) {
      > div:last-child {
        flex-basis: 100%;
      }
    }

    &__is-vertical {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex: 1;
      flex-direction: column;
      width: 100%;

      > div:not(:last-child) {
        border-bottom: $thin-border;
      }
    }
  }

  &__center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__column {
    flex: 1;

    > strong {
      margin: 0.2rem 0;
    }

    > h3 {
      font-size: 1.5rem;
      margin-top: 1rem;
    }

    &__is-vertical {
      border-bottom: $thin-border;
    }
  }

  &__is-late {
    @include until($mobile-break-point) {
      border-left: $thin-border;
    }
    h3 {
      color: $red;
    }
  }

  &__is-late-tag > strong {
    color: $white;
  }

  button {
    border: 0;
    background-color: transparent;
    color: $aqua;
  }

  .button:hover {
    color: $navy;
    background-color: transparent;
  }

  a.button:focus {
    box-shadow: none;
  }

  &__video-separator {
    > *:last-child {
      border-left: $thin-border;
      border-radius: 0;
      height: 3rem;
      width: 4.16rem;
    }

    > a {
      border: 0;
      color: $aqua;
      fill: $aqua;
      padding-top: 0.75rem;
    }

    > a:hover {
      color: $navy;
      fill: $navy;
    }

    svg,
    path {
      fill: inherit;
    }
  }

  &__published-metric {
    display: flex;
    flex-direction: row;
    height: 2.29rem;
    flex-shrink: 0;

    > div {
      flex: 1;
    }
  }

  &__metric-label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
  }

  &__metric-value {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;
    border-left: $thin-border;
  }
}
