.CampaignsTable {
  &__team-selector {
    display: flex;
    align-items: center;

    > a {
      margin-left: 1rem;
    }

    .CreatableTeamSelector--editing {
      flex: 1;
    }
  }

  .Table__pagination {
    .Icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }

  &__platform-icons {
    display: flex;
    align-items: center;

    .Icon {
      margin-right: 0.5rem;
    }
  }
}
