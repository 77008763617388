@import '../../css/themes/matchmade/variables';
@import '~bulma/sass/utilities/mixins';
@import './TabComponents/common';

.Button {
  flex: 1;

  &--wrapped .button {
    // Always split words to two lines
    white-space: normal;
    line-height: 1;

    span {
      max-width: 5rem; // This makes words "Minimum guarantee" to wrap; can't think of a better generic solution now :|
    }
  }

  .button {
    border: none;
    height: 100%;
    width: 100%;

    &,
    &:hover {
      z-index: auto;
    }
    &:not(.is-small) {
      padding: 0.75rem 1.5rem;
    }
  }

  &--round .button {
    width: 4rem;
    height: 4rem;
    border-radius: 209453px;
  }

  &--selected {
    .button,
    .button[disabled] {
      background-color: $aqua;
    }
  }

  &--transparent {
    .button,
    .button[disabled] {
      & {
        background-color: $white;
      }
      & {
        color: $aqua;
      }
      &:hover {
        color: $navy;
      }
    }
  }

  & .button.is-loading:after {
    border-left-color: $navy;
    border-bottom-color: $navy;
  }

  .button.is-danger {
    color: $navy;
  }
}
