@import '../../css/themes/matchmade/variables';
@import '~bulma/sass/utilities/mixins';
@import '~bulma/sass/utilities/initial-variables';

.ConnectYourAccounts {
  .icon.is-small .material-icons {
    font-size: 1rem;
  }

  ul li .ContentPlatformToConnect {
    border-top: 1px solid $mid-light-grey;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  ul li:last-child .ContentPlatformToConnect {
    border-bottom: 1px solid $mid-light-grey;
  }

  &__header {
    display: grid;
    grid-template-columns: 15% 35% 35% 15%; // needs to match .ContentPlatformToConnect
  }

  &__scopes {
    grid-column-start: 2;
  }

  &__requirements {
    grid-column-start: 3;
  }

  @include until($tablet) {
    &__scopes {
      grid-column: 1 / span 2;
    }

    &__requirements {
      grid-column: 3 / span 2;
    }
  }
}
