@import '~bulma/sass/utilities/mixins';

.Maintenance {
  .hero-body {
    justify-content: center;
  }

  .material-icons {
    font-size: 8rem;
    margin-bottom: 3rem;
  }

  a:hover {
    text-decoration: underline;
  }

  h1 {
    font-size: 1.8rem;
    line-height: 120%;
  }

  @include mobile() {
    p {
      font-size: 1rem;
    }
  }
}
