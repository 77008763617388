@import './Icons/Icons';

.ButtonWithLabel {
  display: flex;
  align-items: center;

  .Icon {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    i {
      padding: 0.5rem;
    }
  }
  & strong {
    font-size: 1.75rem;
  }
}

.matchmade .ButtonWithLabel {
  @import '../../css/themes/matchmade/variables';
  &:hover > .Icon {
    background-color: darken($aqua, 10%);
  }
}
