.InfluencerContactInfo {
  display: flex;
  flex-direction: column;

  &__social {
    .icon {
      margin-right: 4px;
    }
  }
}
