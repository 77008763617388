@import '../../../css/themes/matchmade/variables';
@import '../../common/TabComponents/common';

.InstagramAnalyticsTab {
  background-color: $white;
  height: 100%;

  display: grid;
  grid-template-rows: 4rem 6rem auto;

  &__stats-title {
    font-size: 1.5rem;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0.5rem;
  }

  &__stats-rows {
    display: grid;
    // 3 rows
    grid-template-rows: repeat(3, 1fr);
    // 2 columns
    grid-template-columns: repeat(2, 1fr);

    border-bottom: 1px solid $semi-light-grey;
  }

  &__stats-row-title,
  &__stats-row-value {
    border-top: 1px solid $semi-light-grey;
    display: flex;
    align-items: center;
  }

  &__stats-row-title {
    border-right: 1px solid $semi-light-grey;
    padding-right: 1rem;
    justify-content: flex-end;
  }
  &__stats-row-value {
    padding-left: 1rem;
    justify-content: flex-start;
  }

  &__country {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
