.UrlControlInput.control {
  flex: 1;

  &.has-icons-left {
    span.icon.is-left > svg {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
    span.icon.is-left {
      left: 1.2rem;
    }

    input.input {
      padding-left: 3.7rem !important;
    }
  }

  .help {
    font-size: 1rem;
  }
}

.UrlControlInput {
  @import '../../../css/themes/matchmade/variables';

  &__error {
    color: $yellow;
  }
}
