@import '../../css/themes/matchmade/variables';
.SearchInput {
  display: flex;

  .material-icons {
    cursor: default;
    transition: width 100ms ease-out;
    width: 1.75rem;
    color: $semi-light-grey;
  }
  &:hover .material-icons,
  &:focus .material-icons {
    width: 0;
    transition: width 100ms ease-out;
  }

  input {
    width: 0;
    transition: margin-right 100ms ease, width 250ms ease-out, opacity 151ms ease;
    opacity: 0;
    margin-right: 0;
  }

  &:hover input,
  &:focus input,
  & input[value]:not([value='']) {
    width: 8rem;
    transition: width 250ms ease-in, opacity 151ms ease, margin-right 100ms ease;
    opacity: 1;
    margin-right: 1rem;
  }
}
