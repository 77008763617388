.PublisherCampaignPage {
  &__stats {
    // We need this for overlayable Spinner
    position: relative;
  }
  &__kpi {
    margin-bottom: 2.8rem;
  }

  &__kpi-table {
    margin-top: 2.8rem;
  }

  &__header-text {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
}
