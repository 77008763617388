@import '../../../css/themes/matchmade/variables';
@import '../../common/TabComponents/common';

$columns: 30;

.InstagramDemographicsTab {
  background-color: $white;
  display: grid;
  height: 100%;
  grid-template-rows: auto;
  grid-template-columns: repeat($columns, 1fr);

  &.two-rows {
    grid-template-rows: 15rem auto;
  }

  .ChannelCountry {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 1 / span $columns;
  }

  .bottom-border {
    border-bottom: 1px solid $semi-light-grey;
  }
  .left-border {
    border-left: 1px solid $semi-light-grey;
  }
  .full-width {
    grid-column: 1 / span $columns;
  }

  .first-two-fifth-width {
    grid-column: 1 / span (2 * $columns * 0.2);
  }
  .second-three-fifth-width {
    grid-column: 13 / span (3 * $columns * 0.2);
  }

  .first-half-width {
    grid-column: 1 / span ($columns * 0.5);
  }
  .second-half-width {
    grid-column: 16 / span ($columns * 0.5);
  }

  .center-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.5rem 1.5rem;

    > span {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 1280px) {
    .center-content {
      padding: 0.6rem 0.5rem;
    }
  }

  .button-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > span {
      color: $aqua;
    }
  }

  h2 {
    font-size: 0.875rem;
  }
}
