@import '../../../css/themes/matchmade/variables';
@import '~bulma/sass/utilities/mixins';
@import '~bulma/sass/utilities/initial-variables';
@import '../../common/TabComponents/common';

$mobile-break-point: $tablet - 1px;
$default-transition: transform ease-in 150ms;

.ChannelHeader {
  position: relative;
  // This is to have sharp edges even if our content has something with filter: blur(10px)
  overflow: hidden;
  transition: margin 100ms ease-in-out; // For the game tab in influencer cards

  &__channelActionIcon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.75rem;
    &--left {
      left: 0.75rem;
      right: initial;
    }
    &--right {
      left: initial;
      right: 0.75rem;
    }

    button,
    a {
      background-color: transparent;
      color: $white;
      &:hover {
        opacity: 0.5;
        color: $white;
      }
    }

    button {
      border: 0;
      &:first-child {
        margin-left: 0;
      }
      .material-icons {
        color: $white;
      }
    }
  }

  &,
  & a {
    display: flex;
    align-items: center;
    .Avatar {
      margin-right: 1rem;
      .material-icons {
        color: $white;
      }
    }
  }
  & {
    @include until($mobile-break-point) {
      justify-content: left;
      padding: 4.7rem 2rem 5rem;
    }
  }
  padding: 0.5rem 1rem 5rem 1rem;
  &--vertical {
    padding: 2rem 2rem 5rem;
  }

  z-index: 0;

  .Card & {
    // Card has rounded corners, so set top corners just a bit more radius to
    // get rid of the strange halo
    // https://stackoverflow.com/questions/4637361/best-way-to-remove-css-rounded-corner-halo
    border-top-right-radius: 0.36rem;
    border-top-left-radius: 0.36rem;
  }

  &--vertical,
  &--vertical a {
    flex-direction: column;
    justify-content: space-between;

    .influencerLink {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }

    .Avatar {
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }

  &--minimal {
    padding-bottom: 3.4rem;
    padding-top: 1.6rem;

    a {
      .Avatar {
        display: none;
      }
    }
  }

  & div,
  &:hover div,
  &__background {
    transition: $default-transition;
  }

  & .Avatar {
    transform: scale(1);
  }
  &:hover .Avatar {
    transform: scale(1.3);
  }

  &__background {
    top: 0;
    left: 0;
    background: #000000 no-repeat center; // Fallback background
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;

    filter: blur(0.7rem);
  }

  .cardTitle {
    color: $white;

    font-size: 1.2rem;
    margin-right: 1rem;
    @include clamp-to-lines(1.4rem, 3);
    @include until($mobile-break-point) {
      @include clamp-to-lines(1.75rem, 2);
    }
  }
  &--vertical .cardTitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 0.5rem;
    width: 100%;
    text-align: center;
  }
}
