@import '../matchmade/variables';
@import '~bulma/sass/utilities/derived-variables.sass';

.tabs {
  &.is-boxed {
    li.is-active > a {
      color: $navy;
      background-color: $light-grey;
    }
  }
}

.input {
  // For whatever reason Bulma doesn't include default
  // placeholder coloring for basic input (only disabled one),
  // hence we do it here.
  // Not needed when https://github.com/jgthms/bulma/pull/1257 is merged
  @include placeholder() {
    color: rgba($input-color, 0.3);
  }
}

.button {
  // States
  &:hover,
  &.is-hovered {
    background-color: $semi-light-grey;
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      &:hover,
      &.is-hovered {
        background-color: darken($color, 10%);
        border-color: transparent;
        color: $color-invert;
      }

      &:active,
      &.is-active {
        background-color: darken($color, 5%);
        border-color: transparent;
        color: $color-invert;
      }

      &.is-inverted {
        &:hover {
          background-color: darken($color-invert, 5%);
        }
      }
    }
  }
}
