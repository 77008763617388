.matchmade .AuthLanding {
  @import '../../css/themes/matchmade/variables';
  // Matchmade brand color hero element
  .hero.is-main-brand {
    background-color: $navy;
    color: $white !important;

    .content,
    .title,
    .subtitle,
    strong {
      color: $white !important;
    }

    .mm-hero-top,
    .mm-hero-top .content,
    .mm-hero-top .title,
    .mm-hero-top .subtitle,
    .mm-hero-top .strong {
      color: $aqua;
    }

    .mm-hero-bottom,
    .mm-hero-bottom .content,
    .mm-hero-bottom .title,
    .mm-hero-bottom .subtitle,
    .mm-hero-bottom .strong,
    .mm-hero-bottom a {
      color: $white !important;
    }
  }

  .hero .columns > .column {
    display: flex;
    align-items: center;
  }

  .content {
    a:hover,
    .title,
    .title span {
      color: $aqua !important;
    }
  }
}

.AuthLanding {
  // A special three-part Matchmade hero element
  .mm-hero {
    min-height: inherit;
    display: flex;
  }

  .mm-hero-top,
  .mm-hero-bottom,
  .mm-hero-left {
    display: flex;
  }

  .mm-hero-top {
    align-items: flex-end;
    position: relative;
    bottom: -1.6em;
  }

  .tile.is-parent.is-vertical > .mm-hero-top.tile.is-child {
    margin-bottom: 0 !important;
  }

  .mm-hero-bottom {
    align-items: flex-start;
    position: relative;
    padding-top: 1.6em;
  }

  .mm-hero-left {
    align-items: center;
    text-align: right;

    > * {
      width: 100%;
      margin-right: 20%;
    }
  }

  .mm-hero-top,
  .mm-hero-bottom {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;
  }

  .mm-hero-left img {
    margin-left: 15%;
  }
  .mm-hero {
    margin-bottom: -20px;
  }
  .content {
    a,
    a:hover {
      border-bottom: 0; // No bottom borders, bulma
    }
    a:hover {
      text-decoration: underline;
    }
    .title {
      position: relative;
      top: 5px;
    }
    .subtitle {
      margin-bottom: 0;
    }
  }
}
