@import '../../css/themes/matchmade/_variables';

.ToggleContainer {
  &__header {
    &--title {
      font-size: 1.5rem;
      color: #2d3767;
      font-weight: 700;
    }

    &--close,
    &--open {
      padding: 1.2rem 0;
      position: relative;
      cursor: pointer;
      height: auto; // height of the intro

      span {
        position: relative;
      }

      span:before {
        width: 0;
        height: 0;
        border: 0.6rem solid transparent;
        border-top-color: $aqua;
        transform: rotate(0deg);
        transition: transform 375ms ease;
        content: ' ';
        position: absolute;
        transform-origin: top;
        top: 1rem;
        right: -2.5rem;
      }
    }

    &--open {
      span:before {
        transform: rotate(-180deg);
        transition: transform 375ms ease;
      }
    }
  }
}
