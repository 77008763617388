@import '../../../css/themes/matchmade/variables';
@import './common';

.MessagesTab {
  background-color: $white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 1.5rem 1.5rem 1.5rem;

  .messageBubble {
    display: flex;
    border-radius: $radius;
    background-color: $light-grey;
    padding: 1.5rem 1.5rem 3rem 1.5rem;
    position: relative;
    flex-grow: 1;
    width: 100%;
    margin-bottom: 1rem;

    & + .actionButton {
      align-self: flex-end;
    }
  }

  &__messages-link {
    display: flex;
    align-items: center;
    line-height: 1rem;

    & strong:first-child {
      margin-right: 0.5rem;
    }
  }

  &__chat-closed {
    justify-content: center;
    text-align: center;
  }

  &__empty {
    justify-content: space-around;
    padding-top: 1.5rem;

    .Avatar {
      margin-bottom: 1rem;
    }

    .messageBubble {
      padding-bottom: 1.5rem;
      background-color: transparent;

      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .messageText {
    font-size: 1.25rem;
    margin-left: 2rem;
    white-space: pre-line;
    word-break: break-word;
  }

  .textFooter {
    position: absolute;
    bottom: 0.85rem;
    right: 1.5rem;
    font-size: 0.871rem;
  }

  .Avatar {
    background-color: transparent;
  }

  h2 {
    line-height: 1.14;
    font-size: 1.25rem;
    padding: 1.5rem 0;
  }
}
