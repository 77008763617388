.CreateCampaignForm {
  &__CloneCampaign {
    .select:not(.is-multiple):not(.is-loading)::after {
      display: none;
    }
    .Select-control {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
