.Avatar {
  height: fit-content;
  position: relative;

  &__admin-icon {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  div.is-32x32 &__admin-icon,
  div.is-48x48 &__admin-icon,
  div.is-64x64 &__admin-icon {
    font-size: 1.4rem;
  }

  figure.is-77x77 {
    width: 77px;
    height: 77px;
  }
  figure.is-88x88 {
    width: 88px;
    height: 88px;
  }

  .image img {
    border-radius: 290486px;
    object-fit: contain;
    height: 100%;
  }

  &--rectangle .image img {
    border-radius: 0.72rem;
  }

  &--shield .image img {
    clip-path: url(#shield);
    border-radius: 0;
  }
}
