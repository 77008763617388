.ChannelInfo {
  @import '~bulma/sass/utilities/mixins';
  @import '../../../css/themes/matchmade/variables';

  &__table-row {
    display: flex;
    font-size: 1.25rem;
    line-height: 1.4;
    padding: 1rem 0;
  }

  &__table-left {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    text-align: right;
  }

  &__table-right {
    width: 60%;
    display: flex;
    flex-direction: column;

    & > span {
      word-break: break-all;
    }

    &.image {
      width: 6.85rem;
      height: 6.85rem;
    }
  }

  &__channel-name {
    color: $aqua;
    font-size: 2rem;
    line-height: 0.64;
  }

  &__graph {
    max-width: 30rem;
  }

  &__metrics {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -0.3rem;

    @include mobile() {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__metric {
    display: flex;
    align-items: center;

    & > span:nth-child(2n) {
      margin-right: 2rem;

      @include mobile() {
        margin-right: 0;
      }
    }
  }
}
