@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/mixins';

$avatar-margin: 1.5rem;
@import '../../css/themes/matchmade/variables';

.ChatMessage {
  $breakpoint: $desktop + 1px; // 1025px
  position: relative;

  .linkified {
    // Walsheim font default to something funny, so
    // our tracking links _sometimes_ display
    // "multiplication" character instead of "x"
    // So force it to use no font variations for links
    font-variant: none;
  }

  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }

  display: flex;

  & .Avatar {
    margin-right: $avatar-margin;
    margin-left: 0;
    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: 7.5rem;
      top: 2.5rem;
      bottom: auto;
      right: auto;
      border: 1rem solid;
      border-color: $white $white transparent transparent;
      transform: rotate(-135deg);
    }

    @include until($breakpoint) {
      figure {
        height: 32px;
        width: 32px;
      }

      &::after {
        left: 2.8rem;
        top: 0;
        border-right-color: transparent;
      }
    }
  }

  &.is-reverse {
    flex-direction: row-reverse;
    .Avatar {
      margin-left: $avatar-margin;
      margin-right: 0;

      &::after {
        border-color: $white $white transparent transparent;
        transform: rotate(45deg);
        right: 7.5rem;
        top: 2.5rem;
        bottom: auto;
        left: auto;
      }

      @include until($breakpoint) {
        &::after {
          right: 2.8rem;
          top: 0;
          border-top-color: transparent;
        }
      }
    }

    .ChatMessage__name {
      text-align: right;
    }
  }

  &.is-reverse &__content-box.box {
    background-color: $white;
    padding-left: 3rem; // To be inline with action box
    padding-right: 3rem; // To be inline with action box

    @include until($breakpoint) {
      padding: 0.5rem 1rem;
    }
  }

  &__content-box.box {
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto; // If someone decides to type really long text

    @include until($breakpoint) {
      padding: 0.5rem 1rem;
    }
  }

  &__content {
    word-wrap: break-word;
    white-space: pre-line;
    font-size: 1rem;
  }

  &__name {
    color: $navy;
    font-size: 0.8rem;
  }
}
