.ContentPlatformToConnect {
  &__icon {
    margin-right: 0.75rem;
    .ContentPlatformWrapper--icon {
      padding: 0.5rem;
      margin-bottom: 0.2rem;
      width: 2rem;
      height: 2rem;
    }
  }

  &__scope,
  &__requirement {
    display: flex;
    align-items: center;
    line-height: 1.8rem;
  }

  &__scope-content,
  &__requirement-content {
    margin-right: 0.5rem;

    &::before {
      content: '•';
      margin-right: 0.3rem;
    }
  }

  &__connected,
  &__available {
    position: relative;
  }

  &__available {
    ul {
      margin: 0.5rem 0;

      li {
        padding: 0.2rem 0;
      }
    }

    .OnOffToggle.button {
      min-height: 1rem;
      height: 1.5rem;
      padding: 0;
      display: flex;
      justify-content: flex-start;
    }
  }
}
