@import '../../css/themes/matchmade/variables';

@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/mixins';

.CampaignDescriptionModal {
  @include desktop() {
    .modal-card {
      width: 68.57rem;
    }
  }

  .modal-card-head {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: thin solid $semi-light-grey;
    background: $white;

    h3 {
      margin-bottom: 0;
    }
  }

  .modal-card-body {
    padding: 1.2rem 7rem 0;
  }

  @include mobile() {
    .modal-card-body {
      padding: 1.2rem 1.2rem;
    }
  }

  .modal-card-foot {
    padding: 0;
    border-top: 0;
    align-items: stretch;
    justify-content: flex-end;
    border-bottom-left-radius: 0.358rem;
    border-bottom-right-radius: 0.358rem;
    height: 5rem;
  }

  @include tablet() {
    .modal-card {
      padding-bottom: 4rem;
    }
    .modal-card-foot {
      height: 4.5rem;
    }
    &__close_button {
      width: 4.5rem;
    }
  }

  @include mobile() {
    .modal-card {
      padding-bottom: 4rem;
    }
    .modal-card-foot {
      height: 4rem;
    }
    &__close_button {
      width: 4rem;
    }
  }

  &__content {
    padding: 1.5rem 1.5rem 0;
    overflow-y: auto;
    flex-direction: column;
    justify-content: center;

    > strong {
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  &__buttons_title {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  &__buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 2rem 0;
  }

  &__button,
  &__button--disabled {
    flex-basis: 15rem;
    flex-grow: 0;
    text-align: center;

    &-separator {
      background-color: $navy;
      width: 0.05rem;
      height: 4rem;
    }
  }

  &__button--disabled {
    color: $semi-light-grey;

    strong {
      color: $semi-light-grey;
    }
  }

  &__section {
    white-space: pre-wrap;
    margin-bottom: 3rem;

    > strong {
      margin-bottom: 1rem;
    }
  }

  &__section_title {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  &__action_button {
    margin-bottom: 0 !important;
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__close_button {
    flex: 0;
    border-left: thin solid $white;
    width: 5rem;
  }

  &__close_button .button,
  &__action_button .button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
