.ProductSelection .SelectGame {
  &__game-title {
    flex: 2;
  }
  &__soft-launch {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 1rem;

    label {
      display: flex;
      align-items: center;
      > span {
        margin-right: 0.25rem;
      }
    }
  }
}
