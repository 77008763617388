@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/mixins';

@import '../../css/themes/matchmade/variables';

.CampaignTotalCost {
  background-color: $white;
  padding: 1rem;
  border-radius: $radius;
  display: flex;
  justify-content: center;

  &__title {
    margin-right: 0.8rem;
  }

  &__cost {
    margin-left: 0.8rem;
  }

  @include mobile() {
    &__title {
      margin-right: 0.4rem;
    }

    &__cost {
      margin-left: 0.4rem;
    }
  }
}
