@import '../../../css/themes/matchmade/variables';
@import './common';

.PayoutsTab {
  background-color: $white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 1.5rem;
  justify-content: space-around;
  text-align: center;

  h2 {
    line-height: 1.14;
    font-size: 2rem;
    padding: 1.5rem 0;
    font-weight: 700;
  }
}
