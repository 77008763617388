.InstallAttributionContent .PlatformSpecificInputs {
  display: flex;
  flex-direction: row;

  .UrlControlInput {
    &:not(:first-child) {
      margin-left: 0.5rem;
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
