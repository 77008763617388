.FallbackErrorPage {
  .hero-body {
    justify-content: center;
  }
  .material-icons {
    font-size: 8rem;
  }
  h1 {
    margin-bottom: 1rem;
    font-size: 1.8rem;
  }
}
