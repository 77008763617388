.Modal {
  &.modal {
    // more than header
    z-index: 9002;
  }
}

html.modal-open {
  overflow: hidden;
}
