.matchmade .NotFound {
  @import '~bulma/sass/utilities/mixins';
  @import '../../css/themes/matchmade/variables';
  @import '~bulma/sass/utilities/initial-variables';
  @import '~bulma/sass/utilities/derived-variables';
  @import '~bulma/sass/utilities/functions';

  &__email-link {
    color: $navy;
  }
  .title.has-text-centered {
    color: findColorInvert($yellow) !important;
  }
}

.NotFound {
  .not-found-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    i.fa {
      font-size: 30em;
    }
  }
  .hero-foot {
    padding-bottom: 2em;
  }

  &__email-link {
    &:hover {
      text-decoration: underline;
    }
  }
}
