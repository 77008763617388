@import '../../../css/themes/matchmade/variables';

.Input {
  input {
    background: $light-grey;

    &.is-danger {
      color: $red;
    }
  }
}
