.ProductSelection .SelectPlatform {
  &__platforms {
    display: flex;
    width: 100%;

    &--selected {
      font-weight: bold;
    }
  }
}

.matchmade .ProductSelection .SelectPlatform {
  @import '../../../css/themes/matchmade/variables';

  &__platform {
    &--selected {
      color: $navy;

      &:hover,
      &:active,
      &:focus,
      &:visited {
        color: $navy;
      }

      svg {
        * {
          fill: $navy;
        }
      }
    }

    color: $navy;
  }
}
