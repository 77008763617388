.CampaignReport {
  @import '../../css/themes/matchmade/variables';
  padding-bottom: 8rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 2rem;
    padding-bottom: 3rem;
    flex-wrap: wrap;

    h1 {
      font-size: 2.5rem;
      line-height: 1.1;
      margin-bottom: 0.5rem;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 1.1;
      margin-bottom: 0.8rem;
    }

    h3 {
      font-size: 2rem;
      line-height: 1.1;

      strong {
        color: $aqua;
      }
    }
  }

  &__header-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
