@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/mixins';

@import '../../css/themes/matchmade/variables';

.PublishedContentMessage {
  display: flex;
  justify-content: space-between;
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }

  @include until($tablet) {
    flex-direction: column;
  }

  hr {
    color: $navy;
    margin: 0.5rem 0 0.5rem 6.5rem;
    align-self: flex-start;
    width: 75%;

    @include until($tablet) {
      display: none;
    }
  }

  &.is-reverse {
    flex-direction: row-reverse;
    hr {
      align-self: flex-end;
      margin-left: 0;
      margin-right: 6.5rem;
    }

    @include until($tablet) {
      flex-direction: column;
    }
  }

  &__icon {
    .icon.is-medium {
      width: 2.5rem;
      height: 2.5rem;
      background-color: $white;
      border-radius: 290486px;
    }

    .material-icons,
    svg {
      transform: scale(0.8);
    }

    color: $navy;

    width: 96px;
    height: 96px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: 0.5rem;

    position: absolute;

    @include until($tablet) {
      position: static;
      width: auto;
      height: auto;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__negotiation {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }

    p.is-truncated {
      max-width: 30vw;
      color: $aqua;

      &:hover {
        color: $navy;
      }

      @include until($tablet) {
        p.is-truncated {
          max-width: 80vw;
        }
      }
    }
  }

  &__created {
    text-align: center;
    color: $navy;
    font-size: 0.8rem;
  }
}
