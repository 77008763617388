.ActionableNotification {
  // Make sure content doesn't overflow
  &__content.level-item {
    flex: auto;
    display: block;
    padding: 0 10px;
  }

  &.notification.is-warning a.button {
    text-decoration: none;
  }

  &__action:not(:last-of-type) {
    margin-right: 0.25rem;
  }
}
