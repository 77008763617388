@import '../formVariables.scss';

.CampaignCreationWizard .AdvancedSettings .AdvancedSettingsToggle,
.EditCampaign .AdvancedSettings .AdvancedSettingsToggle {
  &--close,
  &--open {
    padding: 1.2rem 1.2rem 1.2rem 3rem;
    position: relative;
    cursor: pointer;
    height: auto; // height of the intro

    &:before {
      width: 0;
      height: 0;
      border: 0.5rem solid transparent;
      // actual color depends on the theme
      border-top-color: black;
      transform: rotate(0deg);
      transition: transform 375ms ease;

      content: ' ';
      display: block;
      pointer-events: none;
      position: absolute;
      transform-origin: top;
      // align the arrow at the middle
      top: 50%;
      left: $arrowRight;
      z-index: 4;
    }
  }

  &--open {
    &:before {
      transform: rotate(-180deg);
      transition: transform 375ms ease;
    }
  }
}

@mixin theme-colors($aqua, $navy) {
  color: $navy;
  font-weight: 700;

  &--close:before,
  &--open:before {
    border-top-color: $aqua;
  }
}

.matchmade .CampaignCreationWizard .AdvancedSettings .AdvancedSettingsToggle,
.matchmade .EditCampaign .AdvancedSettings .AdvancedSettingsToggle {
  @import '../../../css/themes/matchmade/variables';
  @include theme-colors($aqua, $navy);
}
