// We are spreading styles for form elements everywhere
// this file tries to centralize them all into 1 place
// For now, this is only for the new campaign creation page
// Later, we can re-use this file for other pages

// This file contains only style for the basic form elements (input, select etc...)

@import './formVariables.scss';
@import '../../css/themes/matchmade/_variables';

.CreateCampaignFields {
  .field {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    .field-label {
      margin-right: 0;
      text-align: left;
      display: flex;
      align-items: center;

      label {
        font-size: 1.5rem;
        font-weight: bold;

        // always have 1 label per field
        &.label:not(:last-child) {
          margin-bottom: 0;
        }

        & + i.material-icons {
          margin-left: 0.5rem;
        }
      }
    }

    .field-body {
      // all buttons should have the style when using inside field-body
      .button {
        border: none;
        flex: 1;
        margin: 0 0.5rem;
        height: $height;

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }

    // input with icon, default is whatever default size we have
    // add is-small to have a smaller input (same font-size, though)
    .control {
      &.has-icons-left .icon,
      &.has-icons-right .icon {
        height: $height;
      }

      &.is-small input.input {
        height: $smHeight;
      }
    }

    textarea.textarea {
      border: none;

      padding: $padding * 0.75;
    }

    // maybe this can be replaced by SingleValueSelection
    .select {
      width: 100%;
      height: $height;

      select {
        width: 100%;
        border: none;
        height: $height;
        padding: $padding;
      }

      &:after {
        width: 0;
        height: 0;
        border: 0.5rem solid transparent;
        border-top-color: black;
        transform: rotate(0);

        margin-top: -0.2rem;
      }
    }

    .tag {
      font-size: 0.9rem;

      // can't use the <button class="delete" /> of bulma because it messes up
      // the rounded tag
      &.is-deletable {
        cursor: pointer;
        position: relative;

        &:after {
          content: 'close';
          font-family: 'Material Icons';
          display: inline-block;
          margin-left: 0.3rem;
          cursor: pointer;
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }
  }

  button.button {
    &:focus,
    &.is-primary:focus:not(:active),
    &.is-primary.is-focused:not(:active) {
      box-shadow: none;
    }

    // use this when we want to disable the button but still register events
    // It is useful in case we want to show tooltip for a disabled button
    &.is-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .input {
    border-color: transparent;
    border-width: medium;
    &:hover {
      border-color: $middle-grey;
    }
    // All of our inputs are large, but we don't want to do that
    // with font size (which is how Bulma implements large inputs),
    // so explicitly set height here
    height: $height;

    padding: $padding * 0.75;

    &.is-small {
      height: $smHeight;
      font-size: 1rem;
    }

    &[type='number'],
    &[type='number']:disabled {
      font-size: 1.4rem;
      padding-right: 0.5rem;

      // https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }

      -moz-appearance: textfield;
    }
  }

  label.radio {
    input[type='radio'] {
      display: none;

      & + span::before {
        font-family: 'Material Icons';
        font-size: 1.7rem;
        margin-right: 0.35rem;
        content: 'radio_button_unchecked';
      }
    }

    // when selected
    input[type='radio']:checked + span::before {
      content: 'radio_button_checked';
    }

    // when disabled
    input[type='radio']:disabled + span:before {
      border-color: grey;
      opacity: 0.2;
    }

    input[type='radio']:disabled + span:after {
      background-color: grey;
      opacity: 0.5;
    }

    span {
      display: flex;
      align-items: center;
    }

    &.is-small {
      input[type='radio'] + span::before {
        font-size: 1.33rem;
      }
    }
  }

  label.checkbox {
    // the edge of the checkbox box touches the edge of the label
    padding-left: 31px;
    line-height: 200%;

    input[type='checkbox'] {
      display: none;
    }

    // when selected
    input[type='checkbox']:checked + span::after {
      visibility: visible;
      opacity: 1;
    }

    // when disabled
    input[type='checkbox']:disabled + span:before {
      border-color: grey;
      opacity: 0.2;
    }

    input[type='checkbox']:disabled + span:after {
      background-color: grey;
      opacity: 0.5;
    }

    span {
      position: relative;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      &:before {
        content: '';
        // use pixel to align the element more precisely
        left: -30px;
        width: 25px;
        height: 24px;
        border-radius: $radius-small;
        border: 2px solid black;
      }

      &:after {
        // content: '\f00c';
        content: 'done';
        font-family: 'Material Icons';
        // use pixel to align the element more precisely
        height: 1.7rem;
        line-height: 100%;
        left: -28px;
        font-size: 1.5rem;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.1s linear;
        background-color: white;
        color: black;
      }
    }
  }

  // loading state
  .control {
    &.is-loading {
      $fontSize: 1.5rem;
      &:after {
        content: 'loop';
        font-family: 'Material Icons';
        border: none;
        height: auto;
        width: auto;
        animation: spinAround 1000ms infinite linear;
        animation-direction: reverse;

        font-size: $fontSize;

        top: 50%;
        margin-top: -$fontSize * 0.5;
      }
    }
  }
}

.matchmade .CreateCampaignFields {
  @import '../../css/themes/matchmade/variables';

  .InfoTooltip__icon {
    color: $mid-light-grey;
  }

  .field {
    .select {
      &:after {
        border-top-color: $aqua;
      }
    }

    input.input,
    textarea.textarea {
      &.is-inverted {
        background-color: $light-grey;
      }
    }

    .input[disabled],
    .textarea[disabled] {
      background-color: $white;

      &.is-inverted {
        background-color: $light-grey;
      }
    }

    .tag {
      &.is-deletable {
        &:after {
          color: $aqua;
        }
      }
    }
  }

  label.radio {
    // when disabled
    input[type='radio']:disabled + span:before {
      border-color: $dark-grey;
    }

    span:before {
      color: $aqua;
    }

    &.is-inverted {
      span:before {
        color: $white;
      }
    }
  }

  label.checkbox {
    // when disabled
    input[type='checkbox']:disabled + span:before {
      border-color: $dark-grey;
    }

    input[type='checkbox']:disabled + span:after {
      background-color: $dark-grey;
    }

    span {
      &:before {
        border-color: $aqua;
      }

      &:after {
        color: $white;
        background-color: $aqua;
      }
    }

    &.is-inverted {
      span {
        &:before {
          border-color: $white;
        }

        &:after {
          color: $aqua;
          background-color: $white;
        }
      }
    }
  }
}
