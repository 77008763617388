@import '../../css/themes/matchmade/variables';

.CampaignAllocatedBudget {
  padding-left: 2rem !important;

  &__title {
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .field {
    align-self: center;
  }

  .field-body {
    flex-grow: 4;
  }

  .progress {
    position: relative;

    &[value]::-webkit-progress-bar {
      background-color: $light-grey;
    }

    &.show-percentage {
      &:after {
        content: attr(data-percentage) '%';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        font-size: 0.85rem;
        font-weight: bold;
        line-height: 1rem;
      }
      &.rotated:after {
        transform: rotate(180deg);
      }
    }
  }

  .rotated {
    transform: rotate(180deg);
  }
}
