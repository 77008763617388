.CampaignDetails {
  hr {
    margin: 2.5rem -1000rem 2.5rem -1000rem;
  }

  .CreateCampaignFields__tracking.field .field-label {
    align-items: flex-start;
    margin-top: 1rem;
  }
}
