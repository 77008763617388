@import '../campaignCreation/formVariables.scss';

.Select.CustomSelect {
  .Select-control {
    border: none;
    height: $height;
    padding-left: $padding;
    display: flex;

    .Select-multi-value-wrapper {
      display: flex;
      align-items: center;
      flex: 1;

      .Select-placeholder {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 0;
      }

      .Select-value {
        padding-left: $padding;
        display: flex;
        align-items: center;
      }
    }

    &:hover {
      box-shadow: none;
    }

    .Select-arrow-zone {
      align-self: center;
      flex-basis: 3rem;
      padding-right: 0;
    }
  }

  &.Select--multi {
    .Select-multi-value-wrapper {
      .Select-placeholder {
        padding-left: $padding + 1.5rem; // because of magnifying glass icon
      }

      .Select-input {
        margin-left: 0;
      }
    }
  }

  &.Select--single {
    .Select-control {
      .Select-value {
        padding-left: 0;
      }

      .Select-multi-value-wrapper {
        position: relative;
        height: 100%;

        .Select-input {
          height: 100%;
          padding-left: 0;

          input {
            height: 100%;
            padding: 0;
          }
        }
      }
    }
  }

  $menuOuterZIndex: 9001;
  .Select-menu-outer {
    top: $height;
    box-shadow: -4px 0px 7px 0px rgba(0, 0, 0, 0.2), 4px 0px 7px 0px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 0.35rem;
    border-bottom-right-radius: 0.35rem;
    border: none;

    z-index: $menuOuterZIndex;

    &:focus {
      outline: none;
    }
  }

  .arrow-icon {
    font-size: 2rem;
  }

  &.is-open {
    .Select-control {
      z-index: $menuOuterZIndex + 1;
      box-shadow: -4px 0px 7px 0px rgba(0, 0, 0, 0.2), 4px 0px 7px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

.matchmade .CustomSelect {
  @import '../../css/themes/matchmade/variables';

  .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label[role='option'] {
    color: $navy;
  }

  &.is-open,
  &.is-focused {
    > .Select-control {
      background-color: $white;
    }
  }

  .Select-control {
    background-color: $white;
  }

  .Select-menu-outer {
    background-color: $white;

    .Select-menu {
      .Select-option {
        background-color: $white;
        &.is-focused {
          background-color: $mid-light-grey;
          color: $navy;
        }
      }
    }
  }

  .Select-menu-outer {
    .Select-menu {
      .Select-option {
        color: $navy;

        &.is-selected {
          background-color: $navy;
          color: $white;
        }
        &.is-disabled {
          color: $dark-grey;
        }
      }
    }
  }

  &--inverted {
    &.is-open,
    &.is-focused,
    &.is-disabled {
      > .Select-control {
        background-color: $light-grey;
      }
    }

    .Select-control {
      background-color: $light-grey;
    }

    .Select-menu-outer {
      background-color: $light-grey;

      .Select-menu {
        .Select-option {
          background-color: $light-grey;

          &.is-focused {
            background-color: $mid-light-grey;
            color: $navy;
          }
          &.is-disabled {
            color: $dark-grey;
          }
        }
      }
    }
  }
}
