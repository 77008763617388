@import '../formVariables.scss';

.CampaignCreationWizard .AdvancedSettings,
.EditCampaign .AdvancedSettings {
  &__settings {
    flex-direction: column;

    textarea {
      min-height: 13rem; // We need this to be a bit higher
    }
  }
}
