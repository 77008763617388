.AdminPayoutsPage {
  td > time {
    white-space: nowrap;
  }
  &__top {
    display: flex;
    flex-direction: row;
    width: 20%;
  }
  &__item {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  &__success-container {
    margin-bottom: 1rem;
  }

  box > pre {
    white-space: pre-line;
  }

  .MuiAccordionDetails-root {
    flex-direction: column;
  }
}
