.Icon,
.TabIcon,
.mdTabIcon,
.activeTabIcon {
  color: inherit;
  line-height: 1;
  display: flex;
  justify-content: center;
}

.Icon {
  // 77 and 88 are our custom Avatar sizes
  $dimensions: 16 24 32 48 64 77 88 96 128 !default;
  @each $dimension in $dimensions {
    &.is-#{$dimension}x#{$dimension} {
      .material-icons {
        font-size: $dimension * 1px;
      }
    }
  }
}

.mdTabIcon {
  width: 2.4rem !important;
  height: 2.4rem !important;
}

.androidIcon,
.appleIcon,
.laptopIcon {
  margin-bottom: -0.3rem;
}

.mdEditIcon {
  display: flex;
}

.button .Icon.mdCopyIcon {
  width: 1.3rem;
  height: 1.3rem;
  margin-top: 0.2rem;
}

.VideoIcon {
  margin-top: 0.15rem;
  position: relative;

  .spotWrapper {
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: -0.15rem;
  }

  .spot {
    border-radius: 209453px;
    animation-duration: 1.2s;
    animation-name: circle;
    animation-iteration-count: infinite;
    animation-delay: 0.2s;
    border: medium solid;
    box-sizing: border-box;
    opacity: 0;
  }
}

@keyframes circle {
  from {
    width: 0;
    height: 0;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  50% {
    width: 100%;
    height: 100%;
  }

  70% {
    opacity: 1;
  }

  to {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.matchmade {
  @import '../../../css/themes/matchmade/variables';

  .Icon--inactive {
    i.material-icons {
      color: $dark-grey;
    }
    svg * {
      fill: $dark-grey;
    }
  }
  .VideoIcon .spot {
    background-color: $red;
    border-color: $white;
  }
}

.MTurkEmailIcon {
  width: 24px;
  height: 24px;
}

.YoutubeIcon {
  width: 34px;
  height: 24px;
}

.TwitchIcon {
  width: 32px;
  height: 33px;
}

.BilibiliIcon {
  width: 32px;
  height: 32px;
}

.InstagramIcon {
  width: 32px;
  height: 32px;
}

.GoogleIcon {
  width: 32px;
  height: 32px;
}

.FacebookIcon {
  width: 32px;
  height: 32px;
}
