.CampaignsCollectionSelector {
  @import '../../css/themes/matchmade/variables';
  width: 100%;

  .Select-control {
    background-color: $light-grey !important;
    padding-left: 1rem;

    &:before {
      content: 'search';
      font-family: 'Material Icons';
      display: flex;
      align-items: center;
      font-size: 1.21rem;
      // Overriding MultiValueSelector styles
      margin-bottom: 0 !important;
      margin-right: 0.2rem !important;
    }
  }

  &__selected-container {
    display: inline-block;
    position: relative;
    padding-right: 1.5rem;
  }

  .MultiValueSelector__copy {
    position: absolute;
    right: 3px;
    cursor: pointer;
    background-color: $aqua;
    padding: 0 2rem 0 1rem;

    &:after {
      position: absolute;
      font-family: 'Material Icons';
      content: 'file_copy';
      right: 0.4rem;
    }
  }
}
