$rootClass: ModifyOfferOverlay;

.#{$rootClass} {
  background-color: white;
  z-index: 5;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 3rem;

  > *:not(.ModifyOfferOverlay__make-offer):not(.ModifyOfferOverlay__delete-button):not(.ModifyOfferOverlay__negotiation-controls) {
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
  }
  &__negotiation-controls {
    padding: 0.5rem 1rem;
    font-size: 0.85rem;

    .DealTypes > .field-body > .field {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: repeat(3, 1fr);

      .control {
        padding-right: 0.5rem;
      }
    }
  }
  &__suggestions > * {
    padding: 0.5rem 0;
  }
  &__money-inputs > * {
    flex: 1;
    &:not(:last-of-type) {
      margin-right: 0.25rem;
    }
  }
  &__make-offer {
    display: flex;
    > *:not(.MakeOffer__main-action) {
      flex: 0;
    }
    button.button {
      border-radius: 0;
      :first-child:not(:only-child) {
        margin-right: 0.3rem;
      }
    }

    span.Icon {
      margin-left: 0.25rem;
    }
  }
  &__close {
    flex: 0;
  }
  &__delete-button {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    .Button {
      flex: 0;
      button {
        background: transparent; // For _some reason_ transparent button actually has white background >_<
      }
    }
  }

  .MonetaryControlInput {
    input {
      border-radius: 0.35rem;
    }
    .icon.is-left .material-icons {
      padding-bottom: 0.1rem;
      color: #2d3767;
    }
  }

  // The "campaign card" version. We show exactly the same fields there,
  // but in a slightly different order.
  &--horizontal {
    padding-top: 1rem;
    > *:not(.ModifyOfferOverlay__make-offer):not(.ModifyOfferOverlay__delete-button) {
      padding: 0.5rem 1rem;
    }
    .#{$rootClass}__date-offer {
      > :first-child {
        flex: 1;
      }
      .DateOffer {
        margin: 0;
      }
    }
    .#{$rootClass}__mid-section {
      .#{$rootClass}__suggestions {
        flex: 3;
      }
      display: flex;
      .InfluencerStats {
        order: 1;
        flex: 1;
        align-self: center;
        flex-direction: column;
        > * {
          margin: 0.5rem 0;
        }
      }
    }
  }

  &--instagram {
    justify-content: center;

    .#{$rootClass}__mid-section {
      flex-direction: row;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .#{$rootClass}__suggestions {
      margin-left: 3rem;
    }

    .#{$rootClass}__make-offer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
