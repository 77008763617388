@import '~progress-tracker/app/styles/progress-tracker.scss';

$fullSize: 2rem;
$noNumberSize: 1.25rem;
$minimizedSize: 0.8rem;

$transitionDuration: 250ms;

@mixin step-transition() {
  transition-property: max-height, min-width, padding;
  transition-duration: $transitionDuration;
  transition-timing-function: ease-in;
}

.ProgressTracker {
  .progress-tracker {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__step {
    .ProgressTracker__step-index {
      background-color: transparent;
      padding-bottom: 0;
      transition-property: width, height;
      transition-duration: $transitionDuration;

      width: $fullSize;
      height: $fullSize;

      .material-icons {
        font-size: 2.45rem;
      }
    }

    &:hover {
      .ProgressTracker__step-index {
        background-color: transparent;
      }
    }
  }

  .progress-step {
    &:not(:last-child) {
      &:after {
        width: 50%;
        transition-property: width;
        transition-duration: $transitionDuration;
        right: -$fullSize * 0.5;
      }
    }

    &:not(:first-child) {
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 12px;
        left: -$fullSize * 0.5;
        width: 50%;
        transition-property: width;
        transition-duration: $transitionDuration;
      }
    }

    &:after,
    &:before {
      z-index: 0;
      height: 2px;
      top: 13px;
    }
  }

  &__step-label,
  &__step-index {
    font-weight: bold;
  }

  &__step-label {
    padding: 0.5rem 0 0 0;
    max-height: 5rem;
    min-width: 12rem;

    @include step-transition();
    // transition delay is different because we want to delay height transition when this class is applied
    transition-delay: 250ms, 0s, 0s;
  }

  &--no-numbers {
    .ProgressTracker__step-index {
      text-indent: -1000rem;
      transition-property: width, height;
      transition-duration: $transitionDuration;
      width: $noNumberSize;
      height: $noNumberSize;
    }

    .progress-step {
      &:not(:last-child) {
        &:after {
          right: -$noNumberSize * 0.5;
        }
      }

      &:not(:first-child) {
        &:before {
          left: -$noNumberSize * 0.5;
        }
      }

      &:after,
      &:before {
        top: 8px;
      }
    }
  }

  &--minimized {
    .ProgressTracker__step-label {
      width: 0; // still need to reset width so min-width has effect :/
      min-width: 4rem;
      max-height: 0;
      padding: 0;

      @include step-transition();
      // transition delay is different because we want to delay width transition when this class is applied
      transition-delay: 0s, 250ms, 0s;
    }

    .ProgressTracker__step-index {
      text-indent: -1000rem;
      transition-property: width, height;
      transition-duration: $transitionDuration;
      width: $minimizedSize;
      height: $minimizedSize;
    }

    .progress-step {
      &:not(:last-child) {
        &:after {
          transition-property: width, height;
          transition-duration: $transitionDuration;
          right: -$minimizedSize * 0.5;
        }
      }

      &:not(:first-child) {
        &:before {
          transition-property: width, height;
          transition-duration: $transitionDuration;
          left: -$minimizedSize * 0.5;
        }
      }

      &:after,
      &:before {
        top: 5px;
      }
    }
  }
}

$progressTracker: ProgressTracker;
.matchmade .#{$progressTracker} {
  @import '../../css/themes/matchmade/variables';

  &__step {
    .#{$progressTracker}__step-index {
      border-color: $white;
      color: $white;
    }

    &--clickable {
      cursor: pointer;
      &:hover span {
        color: transparentize($white, 0.5);
      }
    }

    &--active {
      .#{$progressTracker}__step-index {
        background-color: $white;
        color: $aqua;
      }
    }
    &--active.#{$progressTracker}__step--clickable {
      &:hover .#{$progressTracker}__step-index {
        background-color: transparent;
        color: transparentize($white, 0.5);
      }
      &:hover .#{$progressTracker}__step-label {
        color: transparentize($white, 0.5);
      }
    }

    &:after,
    &:before {
      background-color: $white;
    }
  }

  &__step-label {
    color: $white;
  }
}
