@import '../../mixins';
@import '../../typography';

// Bulma overrides should be done before we import Bulma
@import './variables';
// TODO move everything below to separate file, so we can import it elsewhere
// Because we use grey as background color, disabled form elements blend into it.
// So use our light-gray as border instead
$input-disabled-border: $semi-dark-grey;

// Default body size to 14px, which is what it was in Bulma 0.3
// 0.4 default is 16px, which sorta makes more sense, but...
$body-size: 14px;
$body-background-color: #f5f5f5;

// Disable shadow
$card-shadow: none;
$card-header-shadow: none;
$dropdown-content-shadow: 0 0 1px $navy;
$navbar-dropdown-boxed-shadow: none;
$box-shadow: none;
$box-link-hover-shadow: none;
$box-link-active-shadow: none;
$button-disabled-shadow: none;
$button-shadow-inset: none;
$input-shadow: none;
$input-focus-box-shadow-size: none;
$input-focus-box-shadow-color: transparent;

// Some default colors were changed to $link in Bulma 6.0, but we want it to be primary
// ...which is kinda odd, once you think about it :/ but since we use navy everywhere
// for the text, it's ok
$link: $aqua;
$link-hover: $navy;
$link-invert: $navy;

$tabs-link-active-border-bottom-color: $aqua;
$tabs-link-active-color: $aqua;

$dropdown-item-active-color: $navy;
$dropdown-item-active-background-color: $aqua;

// Make sure font everywhere is GTWalsheim
$family-sans-serif: $mm-normal-font-family;
// Make sure input colors are correct
$input-color: $navy;
$input-disabled-color: rgba($navy, 0.7);

// Label
$label-color: $navy;

// Table
$table-color: $navy;

// Tag
$tag-radius: $radius;

// Titles and such
$title-color: $navy;
$title-strong-color: $navy;
$subtitle-color: $navy;
$subtitle-strong-color: $navy;

// import last so that we overwrite bulma defaults by doing those definitions before
@import '~bulma/sass/utilities/mixins';

body {
  font-weight: $mm-normal-font-weight !important;
  font-size: $mm-normal-font-size !important;
  line-height: $mm-normal-line-height !important;
  letter-spacing: $mm-normal-letter-spacing !important;
  font-family: $mm-normal-font-family !important;
}

.title {
  font-weight: $mm-title-font-weight !important;
  line-height: $mm-title-line-height !important;
  letter-spacing: $mm-title-letter-spacing !important;
  font-family: $mm-title-font-family !important;
  @media screen and (min-width: $tablet) {
    font-size: $mm-title-font-size !important;
  }
  @media screen and (max-width: $tablet) {
    font-size: $mm-title-font-size-mobile !important;
  }
}

.subtitle {
  font-weight: $mm-subtitle-font-weight !important;
  font-size: $mm-subtitle-font-size !important;
  line-height: $mm-subtitle-line-height !important;
  letter-spacing: $mm-subtitle-letter-spacing !important;
  font-family: $mm-subtitle-font-family !important;
  @media screen and (min-width: $tablet) {
    font-size: $mm-subtitle-font-size !important;
  }
  @media screen and (max-width: $tablet) {
    font-size: $mm-subtitle-font-size-mobile !important;
  }
}

.tagline {
  font-size: $mm-tagline-font-size;
  line-height: $mm-tagline-line-height;
}

// Since Walsheim font we use have different font files
// for different weights & variants (for whatever reason),
// explicitly set it for b/strong tags
b,
strong {
  font-family: $mm-title-font-family !important;
}

.title,
.subtitle {
  color: $navy !important;
}

// We need span matches for React-Intl messages
// (which are always in spans and don't treat className prop)
.title span,
.subtitle span {
  font-weight: inherit;
  color: inherit;
}

// override bulma variables

label,
.label {
  color: $navy;
}

@import '~bulma';

@import './overrides';
@import '../../../css/Tooltip.scss';
@import '../../../css/Select.scss';
@import '../../../css/rtable.scss';

html,
body,
#root {
  min-height: 100vh;
}

html.matchmade {
  background-color: #f5f5f5; // This is what it used to be in Bulma 0.2.3
}

.section {
  background-color: $white;
  @media screen and (max-width: $tablet) {
    padding: 1.5rem 1rem;
  }
}

@import '../_common';
