.TeamsTable {
  @import '../../css/themes/matchmade/variables.scss';

  li {
    display: flex;
    align-items: center;
  }

  &__login-as {
    color: $semi-light-grey;

    &:hover {
      color: $aqua;
    }
  }
}
