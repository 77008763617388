@include fontFace(
  'GT-Walsheim-Bold-Oblique',
  'https://static.matchmade.tv/fonts/GT-Walsheim-Bold-Oblique',
  bold,
  bold
);
@include fontFace(
  'GT-Walsheim-Bold',
  'https://static.matchmade.tv/fonts/GT-Walsheim-Bold',
  bold,
  bold
);
@include fontFace(
  'GT-Walsheim-Medium-Oblique',
  'https://static.matchmade.tv/fonts/GT-Walsheim-Medium-Oblique'
);
@include fontFace('GT-Walsheim-Medium', 'https://static.matchmade.tv/fonts/GT-Walsheim-Medium');

@include fontFaceFamily('GTWalsheim', 'https://static.matchmade.tv/fonts/GT-Walsheim');

$mm-title-font-weight: bold;
$mm-title-font-size: 3em;
$mm-title-font-size-mobile: 2em;
$mm-title-line-height: 1.1em;
$mm-title-letter-spacing: 0;
$mm-title-font-family: 'GT-Walsheim-Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial',
  sans-serif !default;

$mm-subtitle-font-weight: bold;
$mm-subtitle-font-size: 2em;
$mm-subtitle-font-size-mobile: 1.5em;
$mm-subtitle-line-height: 1.2em;
$mm-subtitle-letter-spacing: 0;
$mm-subtitle-font-family: 'GTWalsheim', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial',
  sans-serif !default;

$mm-normal-font-weight: normal;
$mm-normal-font-size: 1em;
$mm-normal-line-height: 1.4em;
$mm-normal-letter-spacing: 0;
$mm-normal-font-family: 'GTWalsheim', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial',
  sans-serif !default;

$mm-tagline-font-size: 1.15em;
$mm-tagline-line-height: 1.4;
