.matchmade .YoutubeSearch {
  @import '../../../css/themes/matchmade/variables';

  .tag {
    color: $navy;
    background-color: $middle-grey;
    border: none;

    &.clickable {
      cursor: pointer;
    }

    .delete {
      background-color: $white;
      &:before,
      &:after {
        background-color: $aqua;
      }
    }
  }
}

.YoutubeSearch {
  @import '../../../css/themes/matchmade/variables';

  &__link-to-previous-page {
    margin-bottom: 1rem;
  }

  .subtitle {
    font-size: 1.5rem !important;
  }

  .ToggleContainer {
    margin-bottom: 2rem;
    padding: 1.5rem;
  }

  .Tabs {
    ul li a {
      font-size: 16px;
    }
    > section,
    > .section {
      padding: 2.5rem 1.5rem;
    }
  }
  > section {
    padding: 2.5rem 1.5rem;
  }

  &__actions {
    display: flex;
    margin-bottom: 2rem;

    .Button .button {
      height: 4rem;
      font-size: 1.1rem;
      font-weight: bold;

      a {
        color: $navy;
      }
    }

    &-edit-search {
      margin-left: 1rem;

      .Icon {
        margin-right: 0.5rem;
      }
    }

    .SaveSearchToCollectionTrigger__button {
      margin-left: 1rem;
    }

    &-search {
      button:before {
        content: 'search';
        font-family: 'Material Icons';
        font-size: 1.5rem;
        margin-right: 0.25rem;
      }
    }
  }

  &__error {
    padding: 2.5rem 1.5rem;
    background-color: $red;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: 0 2rem;
      background-color: $white;
      color: $red;
      padding: 0.5rem 1.5rem;
      border: 0;
      font-size: 1rem;
      outline: none;
    }
  }

  > section,
  > button.is-fullwidth {
    margin-bottom: 2rem;
  }

  .YoutubeSearchSettings {
    > .is-flex-tablet {
      &:not(:last-child) {
        padding-bottom: 1rem;
      }

      .OnOffToggle {
        padding-left: 0;
      }
    }

    &__group {
      align-content: center;
      align-items: flex-start;
      flex: 1;

      &:first-child {
        padding-right: 2rem;
      }
      &:last-child {
        padding-left: 2rem;
      }
      > div {
        padding-bottom: 0.5rem;
      }
    }

    &__group-item {
      flex: 1;
    }

    &__group-item-date-picker {
      flex: 0.5;
      margin-right: 0;
      div {
        padding-top: 1.5rem;
      }
    }

    &__channel-name-input {
      background-color: $light-grey;
      border: none;
      height: 3.2rem;
      padding: 2rem 2rem;
    }
  }
  .tag {
    font-size: 14px;
    align-items: center;
    display: inline-flex;
    height: 2rem;
    justify-content: center;
    line-height: 1.5;
    padding-left: 1rem;
    white-space: nowrap;
    margin-right: 0.25rem;
    margin-top: 0.25rem;

    .delete {
      margin-left: 0.5rem;
    }
  }
}
