.Game {
  @import '../../../css/themes/matchmade/variables';

  border-radius: $radius-large;
  cursor: pointer;
  display: flex;
  overflow: hidden;

  border: medium solid transparent;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &:hover {
    border-color: $aqua;
  }
}
