.SaveSearchToCollectionTrigger {
  &__button button {
    padding: 1rem !important;
    display: flex;
    align-items: flex-end;

    svg {
      margin-right: 0.25rem;
    }
  }
}
