.AdminManagerTeamPage {
  &__name-with-avatar {
    align-items: center;
    .Avatar {
      margin-right: 0.5rem;
    }
  }

  &__modal {
    @import '../../components/modals/BasicModal.scss';
    text-align: center;

    .modal-card-head {
      padding: 20px;
    }

    .modal-card {
      width: 40rem;
    }

    .action_button {
      position: relative !important;
      background-color: transparent;
    }
    .action_button .button {
      border-bottom-right-radius: 0;
      border-color: transparent;
      margin-right: 0.5rem;

      &:hover {
        opacity: 0.8;
      }
    }
    .action_button .button:not(.is-primary):not(.is-danger) {
      background-color: transparent;
      color: $aqua;
    }
    .action_button .button.is-danger {
      background-color: $red;
    }
  }
}
