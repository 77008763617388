.CampaignCreationWizard,
.EditCampaign {
  .AdvancedSetting {
    .AdvancedSettingToggle {
      padding: 1.2rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 100%;
      }

      i.fa {
        font-size: 1.5rem;
        margin: 0 0.5rem;
      }

      &__title {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
      }

      &__current-value {
        margin-left: 0.5rem;
        line-height: 100%;

        span {
          font-size: 1rem;
          text-transform: lowercase;

          &:before {
            content: '(';
          }

          &:after {
            content: ')';
          }
        }
      }
    }
    &--disabled .AdvancedSettingToggle {
      cursor: not-allowed;
    }
  }
}

.matchmade .CampaignCreationWizard .AdvancedSettings .AdvancedSettingToggle,
.matchmade .EditCampaign .AdvancedSettings .AdvancedSettingToggle {
  @import '../../../css/themes/matchmade/variables';

  i.fa {
    color: $mid-light-grey;
  }

  &__current-value {
    span {
      color: $mid-light-grey;
    }
  }
}
