@import '../../../css/themes/matchmade/variables';
@import '../../common/TabComponents/common';
@import '~bulma/sass/utilities/mixins';

$background-transition: transform 150ms ease-in-out;

$color-transition: color 150ms ease-in-out, fill 150ms ease-in-out;
$opacity-transition: opacity 150ms ease;

$button-width: 25%;

$tab-height: 3.5rem;

.TabWithContent {
  margin-top: -$tab-height; // Assumption is that tabs overlay content behind them
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    height: $vertical-tab-height; // Chrome et al
    min-height: $vertical-tab-height; // FF

    &.is-admin > button.link-button {
      color: $orange;
    }
  }

  &--horizontal {
    & .TabWithContent__content {
      @include until($mobile-break-point) {
        height: $vertical-tab-height;
        min-height: $vertical-tab-height;
      }
      height: $horizontal-tab-height;
      min-height: $horizontal-tab-height;
      flex: 1;
    }
    & .TabWithContent__actions {
      height: $actions-height;
    }
  }

  &--minimal {
    & .TabWithContent__content {
      // magic number from Tan's ears
      height: $vertical-tab-height * 1.375;
    }
  }

  &__actions {
    display: flex;
    height: $actions-height;

    &:empty {
      display: none;
    }

    .Button:not(.Button__main-action) {
      z-index: 1;
    }
    .Button {
      flex: 0;
      flex-basis: $button-width;
      margin: 0;

      &:only-child {
        flex: 1;
      }

      &:not(:last-child) {
        border-right: thin solid $white;
      }

      &__main-action {
        flex: 1;
        button *:first-child:not(:only-child) {
          margin-right: 0.3rem;
        }
      }

      & button.button {
        padding: initial;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      // TODO this won't be needed if we would use "buttons has-addons", but one cannot simply :/
      &:not(:only-child) button.button {
        border-radius: 0;
      }
    }
  }

  &__nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $tab-height;

    li {
      text-align: center;
      flex: 1;
      padding: 0.5rem 0;
      cursor: pointer;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      button,
      &.active button,
      &:hover button,
      &:hover button svg > * {
        transition: $opacity-transition;
      }
      &.active button svg > * {
        fill: $navy;
      }
      button,
      button svg > * {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        fill: $white;
        transition: $color-transition, $opacity-transition;
        height: 2.28rem;
      }
      &.active button,
      &.active svg > * {
        color: $navy;
        fill: $navy;
        transition: $color-transition, $opacity-transition;
      }
      &:not(.active):hover button,
      &:not(.active):hover svg > * {
        color: $white;
        fill: $white;
        opacity: 0.5;
        transition: $color-transition, $opacity-transition;
      }
      &.active button,
      &:hover button {
        opacity: 1;
      }
      &:not(.active):hover .spot {
        border: medium solid rgba(255, 255, 255, 0.5);
      }

      &.is-admin {
        button,
        svg > * {
          color: $orange;
          fill: $orange;
        }
        &:not(.active):hover button,
        &:not(.active):hover svg > * {
          color: $orange;
          fill: $orange;
          opacity: 0.5;
        }
      }
    }
  }

  &__background {
    background-color: $white;
    position: absolute;
    height: 100%;
    z-index: 1;
    transition: $background-transition;
    display: flex;
    justify-content: center;
    overflow: hidden;
    clip-path: inset(0 10% -100% 10% round 0.375rem 0.375rem);
    -webkit-clip-path: inset(0 10% -100% 10% round 0.375rem 0.375rem);
  }
}
