@import '../components/campaignCreation/form.scss';

.EditCampaign {
  &.section {
    background-color: transparent;
  }
  position: relative;

  hr {
    margin: 2.5rem -1000rem 2.5rem -1000rem;
  }

  &__header {
    padding-bottom: 5rem;

    h1,
    h2 {
      color: $white;
      font-weight: bold;
      line-height: 1;
    }

    h1 {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 1.2;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__header-background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    height: 11rem;
    width: 100%;
  }

  &__content {
    flex: 1;

    padding-bottom: 5rem;
  }

  &__campaign-description,
  &__campaign-details,
  .CreateCampaignFields {
    label.label {
      font-size: 1.25rem;
    }

    .field .field-body {
      flex: 3 1;
    }

    &.CampaignDescription,
    &.CampaignDetails {
      hr {
        display: none;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button.button {
      font-size: 1.5rem;
      height: 4.5rem;
      text-decoration: none;
    }

    padding-bottom: 5rem;
  }

  &__save-button {
    width: 10rem;
  }

  .CreateCampaignFields {
    .EditCampaign__commission-rate-field {
      margin-bottom: 0.5rem;
    }
  }
}

.matchmade .EditCampaign {
  @import '../css/themes/matchmade/variables';

  .CentToDollarDisplay {
    color: $dark-grey;
  }
}
