.matchmade .AdminLogin {
  @import '~bulma/sass/utilities/mixins';
  @import '../../css/themes/matchmade/variables';
  background-color: $navy;
}

.AdminLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  img {
    width: 14rem;
    margin-bottom: 2rem;
  }
}
