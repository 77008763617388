// Somewhere in our codebase we conveniently reset all the typography and other styles for all the elements
// this attempts to re-reset some of those to render actual text with a little bit nicer styles

.MarkdownTextArea {
  ul,
  ol,
  p {
    margin-bottom: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.25rem;
    margin-top: 1rem;
    font-weight: 600;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  ul,
  ol {
    list-style-position: outside;
    margin-left: 1rem;
  }

  ul {
    list-style-type: disc;
  }

  ul ul {
    margin-bottom: 0;
  }
}
