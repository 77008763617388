.matchmade .TeamManage {
  @import '~bulma/sass/utilities/mixins';
  @import '../../css/themes/matchmade/variables';

  &__no-display-name {
    color: $semi-dark-grey;
  }

  .Avatar .AccountIcon {
    justify-content: left;
  }
}
