.InfluencerYoutubePreferencesForm {
  @import '../../css/themes/matchmade/_variables';

  .youtube-logo {
    margin-bottom: 1rem;
  }

  .CentToDollarInput {
    background-color: whitesmoke;
    border: 0;
  }

  .control {
    margin-bottom: 1rem;
    .label {
      display: flex;
      align-items: center;
    }

    .checkbox {
      display: inline-flex;
      align-items: center;

      .button {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }

  .InfoTooltip {
    margin-left: 0.5rem;
    color: $semi-light-grey;
  }
}
