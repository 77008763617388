@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/mixins';

@import '../../css/themes/matchmade/variables';

$border: thin solid $middle-grey;

.CampaignPerformance {
  min-height: 100%;
  background-color: $white;

  border-radius: 0 0 $radius $radius;

  border-top: $border;

  font-size: 1rem;
  padding: 0;

  .level-item {
    flex: 1;
    padding: 2.5rem 1rem;

    &:not(:first-of-type) {
      border-left: $border;
    }

    @include mobile() {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  .InfluencerCardStat {
    flex-direction: column-reverse;

    &__stat-val {
      font-size: 2.5rem;
    }
  }

  &__campaign-performance {
    font-size: 1.2rem;
  }

  @include mobile() {
    flex-direction: column;

    .level-item {
      padding: 0.5rem;

      &:not(:first-of-type) {
        border-left: none;
      }
    }
  }
}
