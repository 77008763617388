@import '../../../css/themes/matchmade/variables';
@import '../../common/TabComponents/common';
@import '~bulma/sass/utilities/mixins';

@mixin untilMobileBreakPointGameInfo() {
  flex-direction: column;

  &__descriptionWrapper {
    width: 100%;
    height: 50%;
  }

  .is-flex-mobile {
    flex: 1;
    // this should be automatically set by bulma
    // but since this is a mixin, we want it to be usable
    // in any resolutions
    display: flex;
    & > div {
      flex: 1;
      padding: 1rem 0;
      height: 100%;
      &:not(:last-of-type) {
        border-right: thin solid $semi-light-grey;
      }
    }
  }

  &__by {
    border-top: thin solid $semi-light-grey;
  }
}

.GameInfo {
  background-color: $semi-light-grey;
  height: 100%;
  display: flex;

  overflow: hidden;

  &__description-wrapper {
    background-color: $white;
    padding: 1.15rem 1rem 0;
    width: 100%;

    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__description {
    display: flex;
    flex-direction: column;
  }

  &__description-header {
    margin-bottom: 0.5rem;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;

    flex: 1;
    justify-content: flex-start;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;

    strong {
      color: $white;
    }
  }

  &__metrics {
    background-color: $white;
    flex: 1;
    display: flex;
    flex-direction: column;
    @include from($mobile-break-point) {
      border-left: thin solid $semi-light-grey;
    }
    .is-flex-mobile {
      flex: 1;
      & > div {
        flex: 1;
        padding: 1rem 0;
        @include tablet() {
          &:not(:last-of-type) {
            border-bottom: thin solid $semi-light-grey;
          }
        }
      }
    }
  }

  &__by {
    padding: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: thin solid $semi-light-grey;
    strong {
      text-align: center;
    }
    @include clamp-to-lines(1.2rem, 1);
  }

  &__platforms {
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      color: $aqua !important;
      font-size: 1.4rem;
      margin: 0 0.5rem;
    }

    i:hover {
      color: $navy !important;
    }
  }

  &__ratings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    > div {
      padding-bottom: 0.5rem;
    }

    .ratingLabel {
      font-size: 0.878rem;
    }

    .positivePercentage {
      font-size: 1.5rem;
    }
  }

  @include until($mobile-break-point) {
    @include untilMobileBreakPointGameInfo();
  }
}
