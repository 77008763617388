.DeclineOfferModal {
  @import './BasicModal.scss';

  &__info {
    padding: 1rem;
    display: flex;
    align-items: center;
  }

  .modal-card {
    width: 35rem;
  }

  .modal-card-head {
    padding: 2rem 1.42rem;
  }

  .InfoIcon {
    padding-right: 0.4rem;
  }

  form {
    .field {
      padding: 0 1rem;
      margin-bottom: 0;

      &:last-of-type {
        margin-bottom: 1rem;
      }
    }

    .checkbox {
      display: flex;
      align-items: center;
    }
  }
}
