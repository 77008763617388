@import '../../css/themes/matchmade/variables';

.CollectionsTable {
  .Icon {
    svg * {
      fill: $semi-light-grey;
    }
    svg:hover * {
      fill: $aqua;
    }
  }

  &__publisher-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    &-name {
      display: flex;
      align-items: center;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-evenly;
  }

  &__name {
    display: flex;
    align-items: center;
    min-width: 14rem;
    .Icon,
    .DynamicCollectionIcon {
      color: $navy !important;
      margin-right: 0.5rem;
    }
  }

  &__modal {
    @import '../modals/BasicModal.scss';
    text-align: center;

    &-duplicate-label {
      display: flex;
      align-items: center;

      button {
        padding-left: 0;
        margin-right: 0;
      }
    }

    .modal-card-head {
      padding: 20px;
    }

    .modal-card {
      width: 40rem;
    }

    .action_button {
      position: relative !important;
      background-color: transparent;
    }
    .action_button .button {
      border-bottom-right-radius: 0;
      border-color: transparent;
      margin-right: 0.5rem;

      &:hover {
        opacity: 0.8;
      }
    }
    .action_button .button:not(.is-primary):not(.is-danger) {
      background-color: transparent;
      color: $aqua;
    }
    .action_button .button.is-danger {
      background-color: $red;
    }
  }
}
