.EmailTester {
  textarea {
    display: inline-block;
    width: 100%;
  }
  .control {
    textarea {
      height: 120px;
    }
  }
  .email-template-preview {
    textarea {
      height: 300px;
    }
  }
  .Select {
    z-index: 5;
  }
  .email-variables {
    margin-top: 20px;
  }
  .email-template-preview {
    margin-top: 20px;
    iframe {
      width: 100%;
      height: 470px;
      background-color: white;
    }
  }
}
