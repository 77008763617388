.FooterNotification {
  &.notification {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  button.delete {
    margin-left: 2rem;
    min-height: 2.85rem;
    min-width: 2.85rem;
  }

  &__children {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    margin-right: 1rem;
  }
}
