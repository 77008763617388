.RefreshAgreementNotification {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  &__text {
    font-size: 1.2rem;
    line-height: 120%;
    margin-bottom: 1.2rem;

    text-align: center;
  }

  &__button {
    width: 100%;
    padding: 0 1.5rem;

    button {
      width: 100%;
    }
  }
}
