.CampaignDescription .ContentPlatforms {
  &__platforms {
    .control {
      display: flex;
    }

    svg {
      transform: scale(6);
      max-height: 0.35rem;
    }
  }
}

.matchmade .CampaignDescription .ContentPlatforms {
  @import '../../../css/themes/matchmade/variables';

  &__platform {
    &--selected {
      svg {
        * {
          fill: $white;
        }
      }
    }
  }
}
