.InfluencerCardVideo {
  > p.has-text-centered {
    margin: 1rem 0;
  }

  &__video-stats {
    padding-top: 15px;
    table th,
    table td {
      text-align: center;
    }
  }

  &__video-last-30d {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .card-image,
  &__video-thumbnail {
    // To avoid white flash when starting video playback
    min-height: 175px;
    background-color: black;
    background-position: center;
    background-size: cover;

    i.fa-play {
      position: absolute;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      background: black;
      color: white;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.6;
      font-size: 5em;
      margin: 0;
      display: none;
    }

    &:hover i.fa-play {
      display: flex;
    }
  }

  &__video-title {
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
    padding-right: 10px;
  }
}
