@import '../../../../css/themes/matchmade/variables';

.LinkInfluencerContent {
  input {
    background-color: $light-grey;
  }

  &__url-input {
    width: 100%;
  }

  .field:not(:last-child) {
    margin-bottom: 0;
  }
}
