.ProductSelection .SelectVertical {
  @import '../../../css/themes/matchmade/variables';

  &__vertical {
    width: 100%;

    .control {
      display: flex;
    }

    &--selected {
      svg {
        * {
          fill: $white;
        }
      }
    }
  }
}
