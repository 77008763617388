.BasicChannelInfo {
  padding: 1rem;
  position: relative;

  .Spinner--overlay {
    top: 0;
  }

  &__fields {
    margin: 0 1rem;
    flex: 1;
    .control {
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
      flex: 1;

      &.is-half {
        flex: 2;
      }
    }
  }
}
