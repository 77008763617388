@import '../../../css/themes/matchmade/variables';
@import './common';

.AnalyticsTab,
.TwitchAnalyticsTab {
  background-color: $white;
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  padding: 1rem 0;

  &__row {
    display: flex;
    justify-content: center;
    padding: 0 1rem;

    &--title {
      font-size: 1.25rem;
      flex-basis: 100%;
      text-align: center;
      margin-top: 0.6rem;
      font-weight: bold;
    }
  }

  &__tags {
    display: flex;
    justify-content: center;
    position: relative;

    &--line {
      position: absolute;
      width: 100%;
      height: 0.075rem;
      background-color: $semi-light-grey;
      top: 50%;
    }

    &--item {
      padding-right: 0.4rem;
      z-index: 1;
      background-color: $white;

      &:first-of-type {
        padding-left: 0.4rem;
      }

      &-content {
        border-radius: $radius;
        border: solid 0.15rem $semi-light-grey;
        padding: 0.1rem 0.5rem;

        span {
          font-weight: bold;
          font-size: 0.85rem;
        }
      }
    }
  }

  &__stat {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    border-right: thin solid $semi-light-grey;

    &.suggested-price {
      align-items: center;
      margin-bottom: 1rem;
    }

    &:last-child {
      border-right: none;
    }

    .suggested-price &-name {
      color: $aqua;
      margin-bottom: 0.5rem;
    }

    .suggested-price &-value {
      color: $aqua;
      font-size: 2.5rem;
    }

    &-name {
      font-size: 0.875rem;
      font-weight: bold;

      .suggested-price {
        padding-bottom: 1rem;
      }
    }

    &-value {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1;
    }

    &--tooltip-center {
      text-align: center;
      font-size: 0.75rem;
    }
    &--tooltip-justify {
      text-align: justify;
      font-size: 0.75rem;
    }
  }
}
