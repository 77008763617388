.TermsPage {
  &--accepted {
    .TermsPage__navigation-scroller {
      height: 89vh;
    }
  }

  &--do-not-show-text {
    .columns.TermsContent,
    .FooterActions {
      display: none;
    }
  }
}
