.matchmade .CampaignViewForPublisher {
  @import '~bulma/sass/utilities/mixins';
  @import '../../css/themes/matchmade/variables';

  &__estimated-installs,
  &__estimated-cost {
    color: $semi-dark-grey;
  }
}

.CampaignViewForPublisher {
  &__heading > .is-flex {
    justify-content: space-between;
    align-items: baseline;
  }

  .title {
    font-size: 2.5rem;
  }

  .section.heading-block {
    padding: 2.3rem;
    margin-bottom: 1.1rem;
  }

  .section.campaign-description {
    padding: 2.3rem;
  }

  &__description-markdown {
    margin-top: 20px;
    white-space: pre-wrap;
  }

  &__tab-content {
    .influencer-cards {
      min-height: 600px;
      padding: 1.5rem 1.5rem;

      > .SearchInput {
        margin-bottom: 1rem;
      }
    }
  }

  &__estimated-installs,
  &__estimated-cost {
    font-size: 16px;
  }

  .InfluencerCollection {
    margin-bottom: 40px;
  }

  &__heading-container {
    padding-left: 2.3rem;
    padding-right: 2.3rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__header-text {
    margin-bottom: 1rem !important;
  }

  &__header-buttons {
    margin-bottom: 1rem !important;
  }

  &__verify-first {
    height: 25rem;
    font-size: 1.5rem;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      flex-basis: 50%;
      text-align: center;
      margin-bottom: 10rem;
      font-weight: bold;
    }
  }

  .CampaignBrief {
    pre {
      max-height: 30rem;
    }
  }
}
