@import '~bulma/sass/utilities/initial-variables';

.TermsContent {
  &__content {
    max-width: 45rem;
    padding-bottom: 5rem;
  }

  @media screen and (max-width: $widescreen) {
    &__content-column {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: auto;
    }
  }

  @media screen and (min-width: $widescreen) {
    &__content-column {
      flex: none !important;
      width: 75%;
    }
  }
}
