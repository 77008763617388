@import '../common/TabComponents/common';
@import '../../css/themes/matchmade/variables';

.ChatList {
  width: 100%;
  max-height: 40rem;
  overflow-y: auto;
  padding: 0;

  .ChatList__item {
    &.new-message {
      background-color: $semi-light-grey;
    }

    &-title {
      display: flex;
      flex-wrap: nowrap;
      flex-basis: 100%;
      justify-content: space-between;
    }

    &-name {
      color: $navy;
      flex-basis: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-time {
      color: $dark-grey;
      font-size: 0.8rem;
      flex-basis: 25%;
      text-align: right;
    }

    &-content-wrapper {
      padding-right: 0;
    }

    &-content {
      @include clamp-to-lines(1.4rem, 3);
      display: -webkit-box;
    }

    &-message {
      display: inline;
      white-space: normal;
      color: $navy;
      & span:first-of-type {
        color: $navy;
      }
    }

    &-sender {
      display: inline;
      color: $navy;
    }

    &-avatar {
      img {
        max-height: 100%;
      }
    }

    &-no-chats {
      text-align: center;
      font-size: 1rem;
      color: $navy;
    }
  }
}
