@import '../../../css/themes/matchmade/variables';
@import '../../common/TabComponents/common';
@import '~bulma/sass/utilities/mixins';

.Card {
  &.InfluencerCard {
    min-width: 20rem;
    max-width: 33rem;
  }
  @include mobile() {
    max-width: initial;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: $radius;
  overflow: hidden;
  z-index: 0;

  &__overlay {
    background-color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1;
  }
}
