.CollectionSelector {
  @import '../../css/themes/matchmade/variables';
  $defaultPadding: 0.4rem;
  display: flex;
  flex-direction: column;
  min-width: 18rem;
  max-height: 100%;

  &__selected-values {
    padding: $defaultPadding;
  }

  &__selected {
    cursor: pointer;
    position: relative;
    padding-right: 1.3rem !important;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;

    &:after {
      position: absolute;
      font-family: 'Material Icons';
      content: 'close';
      right: 0.3rem;
    }
  }

  &__selected-container {
    display: inline-block;
    position: relative;
    padding-right: 0.25rem;
  }

  &__group-header {
    padding-top: $defaultPadding;
    padding-left: $defaultPadding;
    font-weight: bold;
    span {
      color: $dark-grey !important;
    }
  }

  &__scrollable-content {
    overflow: scroll;
    border-top: thin solid $dark-grey;
    border-bottom: thin solid $dark-grey;
  }

  &__available-values {
    padding-top: $defaultPadding;

    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $defaultPadding;
    }

    > div:hover {
      background-color: $mid-light-grey;
    }

    .value.selected {
      background-color: $navy;
      color: $white;

      svg > path {
        fill: $white;
      }
    }
  }

  &__createCollection {
    padding-top: $defaultPadding;
  }

  .Input {
    .control:first-child {
      width: 100%;
      margin-bottom: 0;
    }
  }

  &__search {
    padding-bottom: $defaultPadding;
  }

  &__search .Input input {
    background: transparent;
  }

  &__search .Input {
    display: flex;
    background-color: transparent;
  }

  &__search .Input:before {
    content: 'search';
    font-family: 'Material Icons';
    display: flex;
    align-items: center;
    font-size: 1.21rem;
  }
}
