@import '../../css/themes/matchmade/variables';

@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/mixins';
.modal-card {
  width: 30rem;
  padding: 0;
}

.modal-card-head,
header.modal-card-head {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: thin solid $semi-light-grey;
  background: $white;
  padding: 3rem 20px;

  h3 {
    margin-bottom: 0;
  }
}

.modal-card-foot,
footer.modal-card-foot {
  padding: 1rem;
  border-top: 0;
  align-items: stretch;
  justify-content: flex-end;
  border-bottom-left-radius: 0.358rem;
  border-bottom-right-radius: 0.358rem;
  height: 5rem;
}

@include tablet() {
  .modal-card-foot {
    height: 4.5rem;
  }
  .close_button {
    width: 4.5rem;
  }
}

@include mobile() {
  .modal-card-foot {
    height: 4rem;
  }
  .close_button {
    width: 4rem;
  }
}

.action_button {
  margin-bottom: 0 !important;
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.close_button {
  flex: 0;
  border-left: thin solid $white;
  width: 5rem;
}

.close_button .button,
.action_button .button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: $aqua;
}
