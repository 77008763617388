// Override react-select variables: https://github.com/JedWatson/react-select/blob/master/scss/select.scss
@import './themes/matchmade/variables';
// TODO Bulma uses em/rem, figure out a good number here as well
//$select-input-height: 2.285em;
$select-input-height: 32px;
$select-input-internal-height: 28px;
$select-input-border-radius: $control-radius;
$select-item-border-radius: 0;
$select-input-border-color: #dbdbdb;
$select-item-border-color: #dbdbdb;
$select-input-border-focus: $aqua;
@import '~react-select/scss/default';

.Select {
  width: 100%;

  &-control {
    line-height: 1.2; // Doesn't seem to have any variable, so have to do it this way :/
  }

  .arrow-icon {
    color: $aqua;
  }
}
