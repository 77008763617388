@import '../formVariables.scss';

.SingleValueSelection {
  .CustomSelect {
    $menuHeight: 15rem;
    .Select-menu-outer {
      min-height: $menuHeight;
    }

    .Select-menu {
      max-height: $menuHeight;
    }
  }
}
