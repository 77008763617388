.AdminInvoicesPage {
  &__top {
    display: flex;
    flex-direction: row;
    width: 20%;
  }
  &__item {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  &__success-container {
    margin-bottom: 1rem;
  }

  .MuiAccordionDetails-root {
    flex-direction: column;
  }
}
