.Autopilot {
  @import '../../../css/themes/matchmade/variables';
  .field-body .Tooltip {
    width: 100%;
  }

  &.field {
    margin-right: 0 !important;
  }

  .TextPill {
    margin-left: 0.5rem;
  }

  &__toggle-icon {
    font-size: 5rem;
    align-self: flex-start;
    color: $aqua;
    user-select: none;
  }

  &__toggle-off {
    color: $dark-grey;
  }

  &__toggle {
    display: flex;
    align-items: center;

    label {
      font-size: 1.25rem;
    }
  }
}
