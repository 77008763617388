@import '../../css/themes/matchmade/variables';

.CountrySelection {
  width: 100%;
  display: block;
  flex: 1;
  min-width: 20rem;

  &.Select {
    border: none;
    color: $navy;

    &.is-disabled {
      cursor: not-allowed;
      .Select-control {
        cursor: not-allowed;
      }
    }

    .Select-menu-outer {
      border: none;
    }

    .Select-placeholder {
      background-color: $light-grey;
    }

    .Select-control {
      border: none;
      background-color: $light-grey;
    }

    .Select-value-icon {
      border-right: none;
    }

    .Select-option {
      background-color: $light-grey;
    }

    .Select-option.is-focused {
      background-color: $middle-grey;
    }

    .Select-arrow-zone .material-icons.arrow-icon {
      line-height: inherit;
    }
  }

  .arrow-icon {
    font-size: 2rem;
  }
}
