@import '../../../css/themes/matchmade/variables';
@import '../../common/TabComponents/common';
@import '~bulma/sass/utilities/mixins';

$default-transition: transform ease-in 150ms;

.CampaignCardHeader {
  position: relative;
  // This is to have sharp edges even if our content has something with filter: blur(10px)
  overflow: hidden;
  transition: margin 100ms ease-in-out; // For the game tab in influencer cards
  padding: 0.5rem 1rem 5rem 1rem;
  z-index: 0;

  &,
  a {
    display: flex;
    align-items: center;
    .Avatar {
      margin-right: 1rem;
      .material-icons {
        color: $white;
      }
    }
  }
  @include until($mobile-break-point) {
    justify-content: left;
    padding: 4.7rem 2rem 5rem;
  }

  div,
  &:hover div,
  &__background {
    transition: $default-transition;
  }

  .Avatar {
    transform: scale(1);
  }
  &:hover .Avatar {
    transform: scale(1.3);
  }

  &__channelType {
    position: absolute;
    top: 0;
    left: initial;
    right: 0rem;
  }

  &__title {
    color: $white;

    font-size: 1.2rem;
    margin-right: 1rem;
    @include clamp-to-lines(1.4rem, 3);
    @include until($mobile-break-point) {
      @include clamp-to-lines(1.75rem, 2);
    }
  }

  &__side-text {
    strong,
    span {
      color: $white;
    }
  }

  &__estimated-earnings-number {
    margin-left: 0.6rem;
  }

  &__background {
    top: 0;
    left: 0;
    background: #000000 no-repeat center; // Fallback background
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;

    filter: blur(0.7rem);
  }
}
