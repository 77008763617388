@mixin fontFace($family, $src, $style: normal, $weight: normal) {
  @font-face {
    font-family: $family;
    src: url('#{$src}.eot'); // IE9 compat
    src: url('#{$src}.eot?#iefix') format('embedded-opentype'),
      // IE8 and below
      url('#{$src}.woff') format('woff'),
      // standards
      url('#{$src}.ttf') format('truetype'),
      // Safari, Android, iOS
      url('#{$src}.svg##{$family}') format('svg'); // legacy iOS

    font-style: $style;
    font-weight: $weight;
  }
}

@mixin fontFaceFamily($family, $src) {
  @font-face {
    font-family: $family;
    src: url('#{$src}-Bold-Oblique.eot'); // IE9 compat
    src: url('#{$src}-Bold-Oblique.eot?#iefix') format('embedded-opentype'),
      // IE8 and below
      url('#{$src}-Bold-Oblique.woff') format('woff'),
      // standards
      url('#{$src}-Bold-Oblique.ttf') format('truetype'),
      // Safari, Android, iOS
      url('#{$src}-Bold-Oblique.svg##{$family}') format('svg'); // legacy iOS

    font-style: italic;
    font-weight: bold;
  }
  @font-face {
    font-family: $family;
    src: url('#{$src}-Bold.eot'); // IE9 compat
    src: url('#{$src}-Bold.eot?#iefix') format('embedded-opentype'),
      // IE8 and below
      url('#{$src}-Bold.woff') format('woff'),
      // standards
      url('#{$src}-Bold.ttf') format('truetype'),
      // Safari, Android, iOS
      url('#{$src}-Bold.svg##{$family}') format('svg'); // legacy iOS

    font-style: normal;
    font-weight: bold;
  }
  @font-face {
    font-family: $family;
    src: url('#{$src}-Medium-Oblique.eot'); // IE9 compat
    src: url('#{$src}-Medium-Oblique.eot?#iefix') format('embedded-opentype'),
      // IE8 and below
      url('#{$src}-Medium-Oblique.woff') format('woff'),
      // standards
      url('#{$src}-Medium-Oblique.ttf') format('truetype'),
      // Safari, Android, iOS
      url('#{$src}-Medium-Oblique.svg##{$family}') format('svg'); // legacy iOS

    font-style: italic;
    font-weight: normal;
  }
  @font-face {
    font-family: $family;
    src: url('#{$src}-Medium.eot'); // IE9 compat
    src: url('#{$src}-Medium.eot?#iefix') format('embedded-opentype'),
      // IE8 and below
      url('#{$src}-Medium.woff') format('woff'),
      // standards
      url('#{$src}-Medium.ttf') format('truetype'),
      // Safari, Android, iOS
      url('#{$src}-Medium.svg##{$family}') format('svg'); // legacy iOS

    font-style: normal;
    font-weight: normal;
  }
}
