@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/mixins';

@import '../../css/themes/matchmade/variables';

.NegotiationMessage {
  display: flex;
  justify-content: space-between;
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }

  @include until($tablet) {
    flex-direction: column;
  }

  hr {
    background-color: $navy;
    margin: auto;
    width: 85%;
    height: 0.08em;
    margin: auto 1rem;

    @include until($tablet) {
      display: none;
    }
  }

  &__icon {
    .icon.is-medium {
      width: 2.5rem;
      height: 2.5rem;
      background-color: $white;
      border-radius: 290486px;
    }
    color: $navy;
    padding: 0 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    @include until($tablet) {
      position: static;
      width: auto;
      height: auto;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__negotiation {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include until($tablet) {
      text-align: center;
    }
  }

  &__split {
    display: flex;
    position: relative;

    &--reversed {
      flex-direction: row-reverse;
    }
  }

  &__created {
    text-align: center;
    color: $navy;
    font-size: 0.8rem;
  }
}
