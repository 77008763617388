.ChannelsTab {
  @import '~bulma/sass/utilities/mixins';
  @import '../../../css/themes/matchmade/variables';
  font-size: 1.25rem;

  h1 {
    font-size: 2.5rem;
    line-height: 1.1;
    margin-bottom: 0.5rem;

    a,
    a:hover {
      color: inherit;
    }
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.1;
    margin-bottom: 0.8rem;
  }

  &__section {
    margin: 3.5rem 5.5rem 7.5rem;

    @include mobile() {
      margin: 1.5rem 0 3rem;
    }
  }

  .Column--sortable {
    color: $aqua;
    font-weight: bold;
  }

  &__title {
    margin-bottom: 5rem;
  }

  &__channel-name {
    color: $aqua;
    line-height: 2;
  }

  &__thumbnail {
    margin-bottom: 1.5rem;
  }

  &__graph {
    height: 37rem;
    padding-top: 5rem;
  }
}
