@import '../../../css/themes/matchmade/variables';

.ProductSelection .Games {
  .columns {
    margin-top: 4rem;
    justify-content: center;

    > .column {
      padding-left: 2rem;
      padding-right: 2rem;

      &:not(:last-of-type) {
        border-right: thin solid $mid-light-grey;
      }
    }
  }

  &__store-icon {
    margin-bottom: 1.5rem;
    transform: scale(2);
    text-align: center;
  }
  &__no-results {
    .material-icons {
      font-size: 2.5rem;
    }
  }
}
