@import '../../common/TabComponents/common';
@import '../../../css/themes/matchmade/variables';

.MobileGame {
  $imgSize: 4.57rem;

  img {
    height: $imgSize;
    width: $imgSize;
  }

  &__data {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: $white;
    flex: 1;

    padding: 0 1.5rem;
    position: relative;

    &-close {
      position: absolute;
      top: 0.1rem;
      right: 0.1rem;
      color: $white;
    }
  }

  &__title {
    strong {
      @include clamp-to-lines(1.4rem, 2);
    }
  }

  &__title {
    @include clamp-to-lines(1.4rem, 1);
  }

  @import '../../../css/themes/matchmade/variables';

  &--selected {
    .MobileGame__data {
      background-color: $aqua;
    }
  }
}
