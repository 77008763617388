@import '~bulma/sass/utilities/mixins';
@import '../../css/themes/matchmade/variables';

.InfluencerCardStat {
  &__stat-val {
    font-size: 2rem;
    line-height: 120%;
  }

  &__stat-info {
    font-size: 0.9rem;
    color: $semi-dark-grey;
  }
}
