.Tabs {
  .matchmade & {
    @import '../../css/themes/matchmade/variables';

    .tabs.is-boxed li.is-active > a {
      color: $navy;
    }

    .tabs ul li {
      background-color: $middle-grey;
      border-color: $light-grey;
    }

    .tabs ul li.is-active {
      background-color: $white;
      border-color: $white;
    }

    > section,
    > .section {
      background-color: $white;
    }
  }

  .tabs ul {
    border: none;
  }

  .tabs:not(:last-child) {
    margin-bottom: 0;
  }

  .tabs.is-boxed a,
  .tabs.is-boxed ul li.is-active a,
  .tabs.is-boxed a:hover,
  .tabs.is-boxed ul li.is-active a:hover {
    background-color: transparent; // Otherwise it looks like a box and overrides the rounded borders on the li
    margin-bottom: 0;
  }

  .tabs.is-boxed li.is-active > a {
    border: none;
  }

  .tabs.is-boxed li > a {
    border: none;
    width: 100%;
    height: 100%;
  }

  .tabs ul li {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-width: 0;
    border-radius: 5px 5px 0 0;
    height: 100%;
    display: flex;
    justify-content: center;

    a {
      display: flex;
      flex-direction: column;
    }
  }
  .tabs ul li:not(:last-child) {
    margin-right: 1px;
  }
  .tabs ul li:last-child {
    margin-right: 0px;
  }

  .tab-panel {
    display: none;
  }

  .tab-panel.is-active {
    display: block;
  }

  > section,
  > .section {
    border-radius: 0 0 5px 5px;
    padding: 1.5rem;
  }
}
