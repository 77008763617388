.AgeGroups {
  &__entry {
    margin-bottom: 0.5rem;
  }
  &__label {
    min-width: 3.75rem;
    margin-top: 0.5rem;
  }
  &__value {
    flex-basis: 100%;
  }
}
