@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/mixins';

@import '../components/common/TabComponents/common';

@import '../css/themes/matchmade/variables';

@import '../components/common/TabComponents/GameInfo.scss';

$thin-border: thin solid $semi-light-grey;

.MessagesPage {
  .section {
    // Make sure we always occupy the whole viewport minus top bar
    min-height: calc(100vh - 4rem);
    padding: 1.5rem;
    background-color: transparent;
  }

  &__grid {
    display: grid;
    grid-gap: 2.75rem;
    grid-template-columns: 30rem auto;
    grid-template-areas: 'sidebar content' 'sidebar content';
  }

  &__header {
    margin-bottom: 2rem;
  }

  &__sidebar {
    grid-area: sidebar;
    position: relative; // For the spinner
    display: flex;
    flex-direction: column;

    @include until($tablet) {
      margin-top: 2rem;
    }
  }

  &__content {
    grid-area: content;
    position: relative;
    overflow-y: auto;
    padding-right: 1rem; // to fit scrollbar
  }

  // Grid is too large for even tablets and small screen (960px), so drop back to regular block display
  @include until($desktop) {
    &__grid {
      display: flex;
      flex-direction: column;
    }
    &__content {
      overflow: initial;
      padding: 0;
    }
  }

  @include from($tablet) {
    // We want to make "almost full height",
    // and not sure how to force that with flex :/
    $height: calc(100vh - 8rem);
    height: $height;
    &__sidebar,
    &__content {
      max-height: $height;
      min-height: 10rem;
    }
  }

  .Card {
    &.CampaignCard,
    &.InfluencerCard {
      max-width: 100%;
      max-height: 40rem;
    }
  }

  .Card.CampaignCard {
    @include mobile() {
      flex-basis: 40rem;
    }

    &--full-height {
      .ChannelHeader,
      .CampaignCardHeader {
        margin-top: -10.8rem;
      }
    }

    .ChannelHeader,
    .CampaignCardHeader {
      padding: 2.6rem 1.2rem 7.2rem 1.2rem;
    }

    .GameInfo {
      @include untilMobileBreakPointGameInfo();
    }
  }

  .CampaignInfoFooter {
    background-color: $white;
  }

  .VideoTab {
    &__content {
      flex-wrap: wrap;

      > div:not(:last-child) {
        border-bottom: $thin-border;
      }

      > div:last-child {
        flex-basis: 100%;
      }
    }
  }

  @media screen and (max-height: 700px) {
    height: 100%;
    &__sidebar,
    &__content {
      max-height: 100%;
    }
  }
}
