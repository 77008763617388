.ProductSelection .SteamGame {
  $imgSize: 4.57rem;

  flex-direction: column;
  align-items: center;

  background-color: #ffffff;

  img {
    height: $imgSize;
    width: $imgSize * 2.4;
  }

  &__data {
    text-align: center;
    padding: 0.1rem 0 1rem 0;
  }

  &--selected {
    background-color: aqua;
  }
}

.matchmade .ProductSelection .SteamGame {
  @import '../../../css/themes/matchmade/variables';

  background-color: $white;

  &--selected {
    background-color: $aqua;
  }
}
