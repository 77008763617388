@import '../../css/themes/matchmade/variables';

.CampaignBudgetLockAmount {
  display: flex;
  align-items: center;

  &__readonly-value {
    margin-right: 0.5rem;
  }
  &__editable-value {
    margin-right: 0.25rem;
  }
}
.CampaignBudgetLock {
  &__amount-block {
    display: flex;
    &-wrapper {
      display: flex;
      align-items: center;
      flex: 1;
    }
    &-label {
      margin-right: 0.5rem;
    }
  }
  &__stats-block {
    display: flex;
  }
  &__warning-block {
    margin-top: 1rem;
  }
  &.box {
    margin-bottom: 0;
    padding: 2rem;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0;
  }

  .stats-elem {
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
    .num {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    .label {
      opacity: 0.8;
      padding: 0.5rem 0;
    }
  }
}
