@import '../../css/themes/matchmade/variables';

.InfluencerCardLayout {
  min-width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  border-radius: $radius;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;

    & .section {
      padding: 0.5rem 0 0 0;
    }

    .tabs {
      margin-top: -10px;
      margin-bottom: 10px;

      &.is-toggle.is-disabled li.is-active a {
        opacity: 0.5;
      }
    }
  }

  .InfluencerCardTabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 0;
  }

  &__footer {
    &--no-border {
      &.card-footer {
        border-top: none;
      }
    }
  }
}
