.matchmade .GamePlatform {
  @import '~bulma/sass/utilities/mixins';
  @import '../../css/themes/matchmade/variables';

  color: $navy;
}

.GamePlatform {
  font-size: 26px;
}
