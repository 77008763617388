/*!
// CSS only Responsive Tables
// http://dbushell.com/2016/03/04/css-only-responsive-tables/
// by David Bushell
// Without any color thingsies
// TODO: Maybe apply to .table instead?
*/
@import '~bulma/sass/utilities/initial-variables';

// Can't use Bulma's @touch mixin here, since it messes up Top Nav bar arrows :/
@media screen and (max-width: $desktop - 1px) {
  .rtable {
    display: block;
    max-width: min-content;

    vertical-align: top;

    overflow-x: auto;

    // optional - looks better for small cell values
    white-space: nowrap;

    border-collapse: collapse;
    border-spacing: 0;
  }

  .rtable,
  .rtable--flip tbody {
    // optional - enable iOS momentum scrolling
    -webkit-overflow-scrolling: touch;
  }

  .rtable th {
    text-align: left;
  }

  .rtable th,
  .rtable td {
    padding: 6px 12px;
  }

  .rtable--flip {
    display: flex;
    overflow: hidden;
  }

  .rtable--flip thead {
    display: flex;
    flex-shrink: 0;
    min-width: min-content;
  }

  .rtable--flip tbody {
    display: flex;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .rtable--flip tr {
    display: flex;
    flex-direction: column;
    min-width: min-content;
    flex-shrink: 0;
  }

  .rtable--flip td,
  .rtable--flip th {
    display: block;
  }

  .rtable--flip td {
    //background-image: none !important;
    // border-collapse is no longer active
    border-left: 0;
  }

  // border-collapse is no longer active
  .rtable--flip th:not(:last-child),
  .rtable--flip td:not(:last-child) {
    border-bottom: 0;
  }
}
