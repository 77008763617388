.InfluencerProfilePage {
  @import '~bulma/sass/utilities/initial-variables';

  &__description {
    margin-bottom: 1rem;
  }
  &__actions {
    margin-top: 2rem;
    text-align: center;
    .button {
      height: 3.5rem;
      font-size: 1.25rem;
      text-decoration: none;
      width: 16.5rem;
      font-weight: bold;
    }
  }

  .subtitle {
    margin-top: 2rem;
  }

  .padding-container {
    @media screen and (max-width: $widescreen) and (min-width: $tablet) {
      padding: 0 10rem;
    }

    @media screen and (min-width: $widescreen) {
      padding: 0 20rem;
    }
  }
}
