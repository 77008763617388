// Place for css variables used across the new influencer card
@import '~bulma/sass/utilities/initial-variables';

$vertical-tab-height: 21rem;
$horizontal-tab-height: 13rem;
$mobile-break-point: $tablet - 1px;
$campaign-card-three-wide-width: 32.7%;
$campaign-card-two-wide-width: 49%;
$wide-card: 40.57rem;
$narrow-card: 26.71rem;
$actions-height: 3.124rem;

// Clamp content to certain number of lines.
// Beware that this works only in WebKit/Blink browsers
// (for FireFox, there is no '...' ad the end of the line)
@mixin clamp-to-lines($lineHeight: 1.14rem, $maxLines: 3) {
  line-height: $lineHeight;
  -webkit-line-clamp: $maxLines;
  max-height: $lineHeight * $maxLines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin flex-center-vertically-and-horizontally {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
