@import '../../css/themes/matchmade/variables';

.ContactEmailsTable {
  &__channel-column {
    display: flex;
    align-items: center;

    a {
      margin-left: 1rem;
    }
  }

  figure.image.is-24x24 img {
    border-radius: $radius;
  }
}
