@use "sass:math";

@import '../../../css/themes/matchmade/variables';
@import '../../common/TabComponents/common';

$columns: 30;

.DemographicsTab {
  background-color: $white;
  display: grid;
  height: 100%;
  grid-template-rows: 10.3rem 5.07rem auto;
  grid-template-columns: repeat($columns, 1fr);

  &__influencerCountry {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottomBorder {
    border-bottom: 1px solid $semi-light-grey;
  }
  .leftBorder {
    border-left: 1px solid $semi-light-grey;
  }
  .fullWidth {
    grid-column: 1 / span $columns;
  }

  .firstTwoFifthWidth {
    grid-column: 1 / span (2 * $columns * 0.2);
  }
  .secondThreeFifthWidth {
    grid-column: 13 / span (3 * $columns * 0.2);
  }

  .firstHalfWidth {
    grid-column: 1 / span ($columns * 0.5);
  }
  .secondHalfWidth {
    grid-column: 16 / span ($columns * 0.5);
  }

  .firstThirdWidth {
    grid-column: 1 / span math.div($columns, 3);
  }
  .secondThirdWidth {
    grid-column: 11 / span math.div($columns, 3);
  }
  .thirdThirdWidth {
    grid-column: 21 / span math.div($columns, 3);
  }
  .centerContent {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.5rem 1.5rem;

    > span {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 1280px) {
    .centerContent {
      padding: 0.6rem 0.5rem;
    }
  }

  .buttonArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > span {
      color: $aqua;
    }
  }

  h2 {
    font-size: 0.875rem;
  }
}
