@import '~bulma/sass/utilities/mixins';
@import '../css/themes/matchmade/_variables';

@mixin flex-and-column() {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.Layout {
  // Chrome has a nasty bug when applying both filter: blur() and transform: scale()
  // to an image, which looks like blinking white border:
  // https://stackoverflow.com/questions/34956803/weird-glitch-when-scale-and-blur-an-image-in-chrome
  // We workaround this by using svg blur filter instead, which
  // seems to work exactly the same as the css variant, but without the nasty effect
  // https://codepen.io/johndjameson/full/xVjgPy/
  .svgBlur {
    filter: url('#sharpBlur');
  }

  @include flex-and-column();
  min-height: 100vh;

  @include desktop() {
    .root {
      @include flex-and-column();
      > div {
        flex: 1;
      }
    }
  }

  .clip-paths {
    position: absolute;
    > svg {
      width: 0;
      height: 0;
    }
  }
}
