.InfluencerTable {
  .Table__pagination {
    span.Icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }

    .is-flex {
      align-items: flex-start;
    }

    .Icon i.material-icons {
      font-size: 1.2rem;
      padding: 0 0.35rem;
    }
  }

  .Icon.CheckIcon {
    justify-content: flex-start;
  }

  &__actions {
    display: flex;
    align-items: baseline;
  }
}

.matchmade .InfluencerTable {
  @import '../../css/themes/matchmade/variables';
  .Table__pagination {
    .Icon:not(.Icon--inactive):not(.mdCheckIcon) {
      color: $navy;
      &:hover {
        color: $aqua;
      }
    }

    svg:hover > path {
      fill: $aqua;
    }
  }
  &__channels {
    svg {
      max-width: 1.5rem;
      max-height: 1.5rem;
    }
    .is-mock {
      svg * {
        fill: $dark-grey;
      }
    }
  }
}
