.AdminDashboardAllocatedBudget {
  @import '../../css/themes/matchmade/variables';

  &__campaign-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Table__pagination {
    span.Icon {
      height: 2rem;
    }
    .is-flex {
      align-items: flex-start;
    }
    svg {
      transform: scale(0.5);
    }
    .Icon i.material-icons {
      font-size: 1.2rem;
      padding: 0 0.35rem;
    }
    .Icon:not(.Icon--inactive):not(.mdCheckIcon) {
      color: $navy;
      &:hover {
        color: $aqua;
      }
    }

    svg:hover > path {
      fill: $aqua;
    }
  }
}
