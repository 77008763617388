@keyframes placeHolderShimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.InfluencerCardPlaceholder {
  @import '../../css/themes/matchmade/variables';
  @import '../common/TabComponents/common';

  $border-radius: 1rem;
  $header-bg-color: $mid-light-grey;
  $header-content-color: $light-grey;
  $body-bg-color: $white;
  $body-content-color: $mid-light-grey;
  $footer-bg-color: $header-bg-color;

  @mixin loadingAnimation() {
    animation-duration: 5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      $header-bg-color 5%,
      $semi-light-grey 25%,
      $header-bg-color 36%
    ) !important;
    background-size: 1000px 100%;
  }

  &__body {
    min-height: $vertical-tab-height;
    height: $vertical-tab-height;
    background-color: $body-bg-color;

    padding: 1rem 0;
    display: flex;
    justify-content: center;
  }

  &__footer {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;

    @include loadingAnimation();
    min-height: $actions-height;
    height: $actions-height;
    background-color: $footer-bg-color;
  }

  &__title {
    height: 1rem;
    border-radius: $border-radius;
    background-color: $semi-dark-grey;
  }

  .ChannelHeader {
    &:hover .Avatar {
      transform: none;
    }
  }

  .ChannelHeader__background {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;

    @include loadingAnimation();
    filter: none;
  }

  .Avatar.is-64x64 {
    figure {
      background-color: $header-content-color;
      border-radius: 64rem;
    }
  }

  // final height must be 26px to match with the actual card
  .cardTitle {
    height: 1rem;
    border-radius: $border-radius;
    background-color: $header-content-color;
    width: 50%;
    margin: 6px 0;
  }
}
