.CampaignCreationWizard .AdvancedSetting,
.EditCampaign .AdvancedSetting {
  @import '../formVariables.scss';
  @import '../../../css/themes/matchmade/variables';

  border-radius: $radius;
  background-color: $white;

  &.field {
    margin-right: 0 !important;
  }

  &__content {
    padding: 1.2rem;
  }

  &--disabled {
    .AdvancedSettingToggle {
      padding: 1.2rem;
    }
    user-select: none;
    cursor: not-allowed;
    color: $mid-light-grey;
  }
}
