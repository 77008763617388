@import '../../../css/themes/matchmade/_variables.scss';

.Countries {
  &__entry {
    margin-bottom: 0.5rem;
  }
  &__label {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    min-width: 20rem; // Same as country input, for consistency
    max-width: 11rem;
    > span {
      // more margin for 'Others' label
      margin-left: 0.5rem;
    }
  }

  &__value,
  &__actions {
    display: flex;
    align-items: center;
  }
  &__actions {
    .control {
      flex: 0;
      button.button {
        color: $semi-light-grey;
        padding: 0 0.5rem;
        &:not([disabled]):hover {
          color: inherit;
        }
      }
    }
    &__add-button {
      visibility: hidden;
    }
    &:hover &__add-button {
      visibility: visible;
    }
  }
}
