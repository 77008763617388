.PaymentInfo {
  .FooterNotification {
    bottom: -10rem;
    animation: fadeOut 5s ease-in-out;

    @keyframes fadeOut {
      from {
        bottom: 0;
      }
      to {
        bottom: -10rem;
      }
    }
  }
}
