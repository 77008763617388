@import '~bulma/sass/utilities/mixins';

.CreateMockInfluencerPage {
  &.section {
    background-color: transparent;
  }
  .section,
  .BasicChannelInfo {
    margin-bottom: 1rem;
  }

  &__stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &--demographic-info {
      width: 100%;
      margin-bottom: 1rem;
    }

    .Countries {
      min-width: 35rem;
    }

    > .Form {
      min-width: 10rem;
      flex-grow: 1;
      margin: 0 0.5rem;
    }
    > :not(:first-child) {
      flex-basis: 0;
    }

    @include touch() {
      > .Form:first-child {
        flex-grow: 1;
        flex-basis: 100%;
      }
    }
  }

  .FloatingFooter__content {
    flex-direction: row;
    align-items: initial;

    .Button {
      margin: 0;
    }
  }
}
