@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/mixins';

.InfluencerCollection {
  .Table {
    .ChannelCountry {
      text-align: left !important;
    }
  }

  &__keyboard-tip .Icon .material-icons {
    font-size: 2rem;
  }

  &__sort-options {
    justify-content: flex-end;

    @include mobile() {
      margin-top: 0.75rem;
      justify-content: flex-start;
    }
  }

  &__sort-by {
    line-height: 220%;
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }

    @include mobile() {
      margin-left: 0;
    }
  }

  &__toolbar {
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 0.75rem;
    align-items: center;
  }

  &__results-info {
    justify-content: space-between;
    margin: 0.75rem 0;
  }

  &__total-influencer {
    display: flex;
    align-items: center;

    font-size: 1.2rem;
    align-items: center;

    .SearchInput {
      margin-left: 0.5rem;
    }
  }

  &__influencers--disabled {
    // Don't allow clicking cards / list
    pointer-events: none;
    // Make cards / list look unclickable
    opacity: 0.5;
  }

  .ContentPlatformSelector {
    .label {
      padding-top: 0.375rem;
      margin-right: 1.5rem;
    }
    button {
      border: none;
      outline: none;
    }
  }

  &__top-pagination,
  &__bottom-pagination {
    &.column {
      display: flex;
    }

    justify-content: flex-end;
    align-items: center;

    @include mobile() {
      justify-content: flex-start;
      margin-top: 0.7rem;
    }
  }

  &__bottom-pagination {
    justify-content: flex-start;
    // make sure that the bottom pagination align with the bottom left edge
    // of the influencer card
    margin-left: 3px;

    @include mobile() {
      margin-left: 0;
      margin-top: 0;
    }
  }
}
