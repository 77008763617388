@import '../../../css/themes/matchmade/variables';
@import '../../common/TabComponents/common';
@import '~bulma/sass/utilities/mixins';

.Card.CampaignCard {
  flex-grow: 1;
  height: 31rem;
  flex-basis: $wide-card;

  max-width: 100%;
  @include until($mobile-break-point) {
    height: 40rem;
    flex-basis: $narrow-card;
  }
  @include from(2 * $narrow-card) {
    max-width: $campaign-card-two-wide-width;
  }
  @include fullhd() {
    max-width: $campaign-card-three-wide-width;
  }

  &.CampaignCard--full-height {
    $slide-difference: 4rem;
    $header-margin: -4.8rem;
    .CampaignCardHeader {
      margin-top: $header-margin;
      @include until($mobile-break-point) {
        margin-top: $header-margin - $slide-difference;
      }
    }
    .TabWithContent__content {
      height: $vertical-tab-height + 3.2rem;
      min-height: $vertical-tab-height + 3.2rem;
    }
  }

  .MessagesTab {
    h2 {
      display: none;
    }
    .messageBubble {
      padding-bottom: 0;
      margin-top: 0.5rem;

      .textFooter {
        bottom: 0.4rem;
      }
    }

    &__empty {
      flex-direction: row;
      justify-content: center;

      .Avatar {
        margin-right: 1rem;
        margin-bottom: 0;
        figure {
          width: 120px;
          height: 120px;
        }
        @include tablet() {
          figure {
            width: 88px;
            height: 88px;
          }
        }
      }

      .messageBubble {
        padding-bottom: 1.5rem;
        flex-direction: row;
        @include until($mobile-break-point) {
          .Avatar {
            margin-right: 0;
            margin-bottom: 1rem;
          }
          flex-direction: column;
          background-color: transparent;
          align-items: center;
        }
      }
    }
  }
}
