.CollectionSelectorTrigger {
  @import '../../css/themes/matchmade/variables';

  display: inline-block;
  cursor: pointer;

  &__button {
    .Icon {
      margin-right: 0.3rem;
    }

    span {
      font-size: 1rem;
      color: $white;
      font-weight: bold;
    }
  }
}

.CollectionSelectorModal {
  .modal-card {
    width: 50%;
    height: 80%;
  }
}
