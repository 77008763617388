.StatisticsTab {
  @import '~bulma/sass/utilities/mixins';

  h1 {
    font-size: 2.5rem;
    line-height: 1.1;
    margin-bottom: 0.5rem;

    a,
    a:hover {
      color: inherit;
    }
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.1;
    margin-bottom: 0.8rem;
    display: inline-block;
  }

  &__section {
    min-height: 82rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 3.5rem 5.5rem;

    @include mobile() {
      margin: 1.5rem 0;
    }
  }

  &__graph {
    height: 42rem;
    margin-bottom: 5rem;
  }

  &__demographics-graph {
    height: 32.5rem;
  }

  &__demographics-graph-title {
    margin: 6rem 0 2.5rem;
  }

  &__metrics {
    padding-top: 4rem;
    display: flex;
    justify-content: space-between;
  }

  &__metrics-box {
    flex-grow: 1;

    h1 {
      font-size: 3.5rem;

      @include mobile() {
        font-size: 2rem;
      }
    }

    h2 {
      @include mobile() {
        font-size: 1.2rem;
      }
    }
  }
}
