.Toggles {
  @import '../../../css/themes/matchmade/variables';

  label.label {
    text-align: left;
  }

  .field-label {
    flex-basis: 1.05rem;
  }

  .field-body .control:not(:last-child) {
    padding-right: 0.25rem;
  }

  button.button span {
    font-weight: 700;
  }

  &__tag,
  &__button.button {
    font-size: 0.85rem;
  }
  &__tag {
    font-weight: bold;
    border: solid 0.15rem $semi-light-grey;
  }
}
