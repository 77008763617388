@import '../../css/themes/matchmade/variables';

.FooterActions {
  .container {
    display: flex;
    justify-content: center;
  }

  button.button {
    height: 3.5rem;
    font-size: 1.25rem;

    text-decoration: none;
  }

  &__next-button.button {
    width: 16.5rem;
    font-weight: bold;
  }

  &__back-button.button {
    padding: 0;
    margin-right: 4rem !important;
    font-weight: bold;

    &.is-text {
      color: $aqua;
    }

    &:focus:not(:active),
    &.is-text:active {
      box-shadow: none;
      background-color: transparent;
    }
  }

  &--floating {
    $footerSpacing: 1rem;
    $gradientHeight: 5rem;

    position: fixed;

    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    z-index: 5;

    padding-top: $footerSpacing;
    padding-bottom: $footerSpacing;

    background-color: $light-grey;

    &:before {
      content: '';
      width: 100%;
      height: 5rem;
      position: absolute;
      left: 0;
      top: -$gradientHeight;
      z-index: 5;
      // theme specific color is set below
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $light-grey);
    }
  }
}
