.PaymentInfoFormForPublisher {
  .section {
    margin-bottom: 1rem;
  }
  .checkbox {
    display: flex;
    align-items: center;

    .OnOffToggle {
      margin-left: -1rem;
      margin-right: 0;
    }
  }
}
