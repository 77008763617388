@import '../../../css/themes/matchmade/variables';

.PercentageTable {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tableItem {
    border-top: thin $semi-light-grey solid;
    display: flex;
    justify-content: space-between;
    padding-left: 0.8rem;

    .label {
      font-size: 0.87rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0.05rem 0;
    }

    .fixedWidthPercentage {
      font-size: 0.87rem;
      width: 2.7rem;
      margin: 0.05rem 0.5rem;
      text-align: right;
    }
  }
}
