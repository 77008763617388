@import '../formVariables.scss';

.MultiSelectButtonWithDefault {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  div.buttons {
    flex: 1;

    border: none;
    padding: 0;

    margin: 0 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:not(:last-child) {
      margin-bottom: 0;
    }

    .button {
      &:first-child {
        border-right: thin solid black; // actual color depends on the theme
      }

      &:first-child,
      &:last-child {
        margin: 0;
      }

      &:only-child {
        border: none;
      }

      margin: 0;
      font-size: 1.25rem;
      line-height: 100%;
    }

    .MultiSelectButtonWithDefault__default-button {
      flex-grow: 0 !important;
      padding-left: 2rem;
      padding-right: 2rem;

      font-weight: bold;
      font-size: 1rem;
    }
  }

  &__button {
    font-weight: bold;
  }
}
