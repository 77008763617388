.TosConfirmationModal {
  @import './BasicModal.scss';

  .modal-card {
    width: 40rem;

    .checkbox {
      display: flex;
      align-items: center;

      .OnOffToggle {
        margin-left: -1rem;
        margin-right: 0;
      }
    }
  }
  .modal-card-head {
    padding: 20px 0;
  }
}
