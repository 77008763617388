@import '../../css/themes/matchmade/variables';

.CampaignInfoFooter {
  display: flex;

  margin-top: 1rem;
  border-radius: $radius;

  &__text {
    font-size: 1rem;
    margin-bottom: 0;
    line-height: 100%;

    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }

  &__need-help {
    .Avatar {
      margin-right: 0.5rem;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-left: thin solid $semi-light-grey;
    justify-content: center;

    flex: 2;
    padding: 0.7rem;
  }

  small p {
    line-height: 1.33;
  }

  &__tooltip {
    max-width: 20rem;
  }
}
