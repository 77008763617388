.InlineTeamManagement {
  &__team-selector {
    display: flex;
    align-items: center;

    > button {
      margin-left: 1rem;
    }

    .CreatableTeamSelector--editing {
      flex: 1;
    }
  }

  &__team-edit-icon .mdEditIcon {
    visibility: hidden;
    pointer-events: none;
  }

  &__team-edit-icon:hover .mdEditIcon {
    visibility: visible;
    pointer-events: all;
  }
}
