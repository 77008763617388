// Here be custom (color) variables for Matchmade theme

$green: #64d282;
$semi-dark-grey: #bebfbf;
$middle-grey: #dbdbdb;
$dark-grey: #949494;
$navy: #2d3767;
$aqua: #55cdd7;
$red: #fa506e;
$yellow: #ffc800;
$orange: #ff9600;
$light-aqua: #aae6eb;
$light-grey: #f5f5f5;
$semi-light-grey: #e0e0e0;
$mid-light-grey: #d8d8d8;
$blue-grey: #8187a4;
// also affects arrow style (see NegotiationActionBox.scss)
$white: #ffffff;

// Overrides for Bulma default colors
$black: #171715;
$grey-dark: #636362;
$grey-light: $semi-dark-grey;

// Content platform colors
$youtube: #ff0000; // https://www.youtube.com/about/brand-resources/#logos-icons-colors
$twitch: #9146ff; // https://brand.twitch.tv/

// Overriding variables from here:
// https://github.com/jgthms/bulma/blob/master/sass/utilities/variables.sass

$grey-darker: hsl(0, 0%, 21%) !default;
$white-ter: hsl(0, 0%, 96%) !default;
$white: hsl(0, 0%, 100%) !default;

$turquoise: $aqua;
$blue: $navy;
$purple: #aa6ebe;
$red: $red;

$primary: $aqua !default;
$primary-invert: $navy; // Force bulma to use navy as primary invert, since that's what our designs do

$info: $navy !default;

$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;

$light: $white-ter !default;
$dark: $grey-darker !default;

$text: $navy;
$text-strong: $navy;
$table: $navy;

$button-color: $navy;

$border-color: #f1f1f1;

// Generic border radius for everything
$control-radius: 0.35rem;
$radius: 0.35rem;
$radius-small: 0.2rem;
$radius-large: 0.5rem;
// used when we want to highlight that something is disabled
$disabled-opacity: 0.5;

$navbar-item-color: $grey-dark;

// manual variable setting to avoid importing bulma's entire buttons.sass and all utilities
$control-border-width: 1px;
$button-border-width: $control-border-width;
// v0.8.0: Controls and buttons are now 2.5em high. You can revert this resizing by setting these previous values
// https://github.com/jgthms/bulma/blob/master/CHANGELOG.md#080
$control-height: 2.25em;
$control-padding-vertical: calc(0.375em - #{$control-border-width});
$control-padding-horizontal: calc(0.625em - #{$control-border-width});
$button-padding-vertical: calc(0.375em - #{$button-border-width});
$button-padding-horizontal: 0.75em;
