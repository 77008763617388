@import '../../../css/themes/matchmade/variables';

.DealItemTab {
  $border: thin solid $semi-light-grey;
  &__title {
    padding: 1.1rem 2rem 0.8rem;
    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      text-align: center;
    }
  }

  display: grid;
  grid-template-columns: auto;
  height: 100%;
  background-color: $white;

  &--published {
    grid-template-rows: 15% 12.5% 12.5% 20% 45%;
  }

  &--in-review {
    grid-template-rows: 15% 12.5% 12.5% 20% 45%;
  }

  &--unpublished {
    grid-template-rows: 30% 35% 35%;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: $border;
  }

  &__two-column-row {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    border-bottom: $border;
  }

  &__two-column-row-label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
    border-right: $border;
  }

  &__two-column-row-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;
  }

  &__published-content {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 40% 60%;

    border-bottom: $border;

    strong {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__content-url {
    button {
      border: 0;
      background-color: transparent;
      color: $aqua;
      border-radius: 0;
    }

    .button:hover {
      color: $navy;
      background-color: transparent;
    }

    svg,
    path {
      fill: inherit;
    }
  }

  &__content-url {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;

    a.button {
      padding: 0;
    }

    a.button:focus {
      box-shadow: none;
    }

    a {
      border: 0;
      color: $aqua;
      fill: $aqua;
    }

    a:hover {
      color: $navy;
      fill: $navy;
    }
  }

  &__link-to-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-right: $border;
    padding-right: 0.75rem;

    height: 100%;
  }

  &__copy-content-url {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;

    height: 100%;
  }

  &__tracking-url {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  &__publishing-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-bottom: $border;

    h3 {
      font-size: 1.5rem;
      margin-top: 1rem;
    }
  }
}
