// Common stylesheet for all admin boards
.AdminDashboardGeneric,
.AdminDashboardCampaigns,
.AdminDashboardEmail,
.AdminDashboardCollections,
.AdminDashboardInfluencers,
.AdminDashboardManagers,
.AdminDashboardPublishers {
  &.container {
    margin-bottom: 3rem;
  }

  > div:first-child {
    flex: 1;
  }

  .hero {
    min-height: 85vh; // So we don't have scroll because of header
    align-items: center;
  }

  .hero-body {
    align-items: center;
    display: flex;
  }

  &__display-name {
    display: flex;
    align-items: center;
    .Avatar {
      margin-right: 0.25rem;
    }
  }

  &__date-field {
    // To fit dates in two rows, like
    // 18th May
    // 2018
    min-width: 6rem;
  }

  .mdCheckIcon {
    margin: 0.3rem;
  }

  // Both mm and gi share colors for this style
  .matchmade & {
    @import '../../css/themes/matchmade/variables';

    figure.image.is-24x24 img {
      border-radius: $radius;
    }

    .Icon:not(.mdCheckIcon) {
      color: $semi-light-grey;

      &:hover {
        color: $aqua;
      }
    }
  }
}
