.Adjust {
  .help {
    font-size: 1rem;
  }
}

.Adjust {
  @import '../../../../css/themes/matchmade/variables';

  &__info-wrapper {
    display: flex;
    & > * {
      padding: 0.5rem;
    }
  }

  &__info-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__info-line {
    margin-bottom: 0.3rem;
  }

  &__info-icon {
    padding-left: 0;
  }

  &__error {
    color: $yellow;
  }
}
