@import '~bulma/sass/utilities/initial-variables';
@import '../../css/themes/matchmade/index';

.TermsNavigation {
  position: sticky;
  top: 0;

  &:after {
    content: '';
    width: 100%;
    height: 3rem;
    position: absolute;
    left: 0;
    bottom: -1rem;
    z-index: 5;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(255, 255, 255, 0)),
      to(#f5f5f5)
    );
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), #f5f5f5);
    background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0), #f5f5f5);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f5f5f5);
  }

  li {
    margin: 1rem 0;
    a {
      color: inherit;

      &:hover {
        color: $link;
      }
    }
  }

  &--scroller {
    overflow-y: auto;
    height: 90vh;
    padding-bottom: 1rem;
  }

  @media screen and (max-width: $widescreen) {
    &__navigation-column {
      display: none !important;
    }
  }
}
