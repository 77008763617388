.ContentPlatformWrapper {
  @import '../../css/themes/matchmade/variables';

  display: flex;
  align-items: center;

  &.tag {
    padding-left: 1rem;
    padding-right: 1rem;
    color: $white;
    font-weight: bold;
  }
  &--icon.tag {
    padding: 0.6rem;
    height: 3rem;
    width: 3rem;

    // for svg icons
    path {
      fill: $white;
    }
  }
  &--youtube.tag {
    background-color: $youtube;
  }
  &--twitch.tag {
    background-color: $twitch;
  }
  &--instagram.tag {
    background: #f09433;
    background: linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }
}
