@import '../../css/themes/matchmade/variables';

.ChannelCountry {
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  &__stat-val {
    font-size: 2rem;
    line-height: 120%;
  }

  &__flag {
    margin-right: 0.3rem;
    box-shadow: 0 0 0.01rem $dark-grey;
  }
}
