.InstallAttribution {
  &__partner-selector {
    margin-bottom: 1rem;
  }

  &__attribution-partner {
    > .control {
      margin-bottom: 1rem;
    }
  }
}
