@import '~bulma/sass/utilities/mixins';

.CampaignKpiTable {
  font-size: 0.875rem;

  &__summary {
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;

    .card {
      text-align: center;
      justify-content: center;
      display: flex;
      padding: 20px;
      margin-right: 10px;
      flex: 1;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .table tr th {
    cursor: pointer;
  }
}
