.InfluencerCardHeader {
  &__fallback-avatar > i {
    font-size: 4.5rem;
  }

  &.card-header {
    box-shadow: none;
    min-height: 75px;
    border-bottom: thin solid #d3d6db;

    a {
      width: 100%;
    }
    .media {
      align-items: center;
      width: 100%;

      img {
        border-radius: 50%;
      }

      .media-left {
        margin-left: 0.4rem;
      }

      .media-right {
        height: 100%;
      }

      .media-content {
        display: flex;
        flex-grow: 1;
        text-align: left;
      }

      .media-content__title {
        width: 0;
        flex: 1;

        strong {
          a {
            color: inherit;
          }
        }
      }

      .media-content__stars {
        color: #69707a;
        flex: 1;
        align-items: center;
        display: flex;
        justify-content: flex-end;

        .icon {
          font-size: 18px;
          width: 20px;
        }
      }
    }
  }
}
