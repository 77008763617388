.InfluencerSettingsForm {
  .Select-control {
    background-color: whitesmoke;
    border: 0;
  }

  .Select.is-focused > .Select-control,
  .Select.is-open > .Select-control {
    background-color: whitesmoke;
  }

  .control {
    margin-bottom: 1rem;
  }
}
