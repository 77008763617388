.CreateInvoiceItemForm {
  .metadata__field {
    .input {
      max-width: 45%;
    }
    .button {
      max-width: 10%;
    }
  }
}
