.CampaignReportVideos {
  @import '~bulma/sass/utilities/mixins';
  @import '../../../css/themes/matchmade/variables';
  padding: 2rem 3rem;
  font-size: 1.25rem;
  line-height: 1.75rem;

  @include mobile() {
    padding: 2rem 1rem;
  }

  h2 {
    font-size: 2rem;
    padding-bottom: 6rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__video-block {
    flex: 1;
    padding-right: 3rem;
    padding-bottom: 3rem;
    min-width: 29rem;

    & img {
      width: 29rem;
      height: 16rem;
    }
  }

  &__thumbnail {
    > img {
      margin-bottom: 1rem;
    }
  }

  &__video-texts {
    display: flex;
    margin-bottom: 1rem;

    & i {
      margin-right: 1rem;
    }
  }
}
