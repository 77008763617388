.GameHeader {
  @import '../../css/themes/matchmade/variables';

  &__header-text {
    > strong {
      color: currentColor !important;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .columns {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .is-clearfix {
    border-bottom: 1px solid #f1f1f1;
  }

  .profile-heading {
    padding: 0;

    .background {
      background-repeat: no-repeat;
      background-position: 50%;
      height: 176px;
      background-size: cover;
      background-color: #fff;
    }

    .cover {
      padding-top: 30px;
      padding-right: 20px;

      div {
        float: right;
        background-size: cover;
        border-radius: 22px; // on iOS this should be (10/57 * size)
      }

      div.wide {
        border-radius: $radius;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .intro {
      border-right: 1px solid #f1f1f1;
      margin: 0;
      padding: 30px 30px 20px 30px;
      border-right: none !important;

      .tagline {
        padding: 20px 0;
        font-size: 16px;
        line-height: 1.4;
      }
    }

    .background {
      background-repeat: no-repeat;
      background-position: center;
      height: 176px;
      background-size: cover;
      background-color: #ffffff;
    }

    .left-stat,
    .right-stat,
    .center-stat {
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
      flex: 1;
    }

    .left-stat:not(:last-child),
    .center-stat:not(:last-child) {
      border-right: 1px solid #f1f1f1;
    }

    .left-stat {
      border-left: 1px solid #f1f1f1;
    }

    .stat-key {
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
    }
    .stat-val {
      font-size: 35px;
      line-height: 50px;
      font-weight: bold;
    }
  }

  .platforms {
    font-size: 26px;
    margin-bottom: 10px;

    a {
      margin-right: 10px;
    }
  }

  .stat-container {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
}
