@import './form.scss';
@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/mixins';

$navBarHeight: 46px;
$animationTiming: 0.2s;

.CampaignCreationWizard {
  &.section {
    background: transparent;
  }
  &__content {
    flex: 1;
  }

  &__header {
    transition: height $animationTiming ease-in-out;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 5;
    width: 100%;

    @include touch() {
      // mimic section padding
      padding: 0 1.5rem;
    }

    h1 {
      font-size: 2.5rem;
      line-height: 1.1;
    }

    .container {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    h2.title {
      margin-bottom: 0.5rem;
    }
    .title {
      align-self: flex-start;
      height: 3rem;
      transform: translate3d(0, 0, 0);
      &,
      &--hidden {
        transition-property: height, transform;
        transition-duration: $animationTiming;
        transition-timing-function: ease-in;
      }
      &--hidden {
        padding: 0;
        margin-bottom: 0 !important;
        transform: translate3d(0, -10rem, 0);
        height: 0;
      }
    }
  }

  &__header-background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
  }

  &__body {
    padding-top: 8rem;
    padding-bottom: 9rem;
    .section {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
      @include touch() {
        padding-left: 5rem;
        padding-right: 5rem;
      }
    }
  }

  &__footer {
    background-color: transparent;
  }

  &__next-button {
    &.button {
      font-size: 1.5rem;
      height: 4.5rem;
    }
  }

  .CreateCampaignFields {
    .field {
      .field-label {
        flex: 1;
      }

      .field-body {
        flex: 3;
      }
    }
  }

  .HeaderNotification {
    align-self: center;
  }

  &--has-notification {
    .ProgressTracker {
      margin: 0.5rem 0;
    }
  }

  .MonetaryControlInput input.input,
  .CentToDollarInput + span .material-icons {
    font-size: 1.4rem;
  }
}

.matchmade .CampaignCreationWizard {
  @import '../../css/themes/matchmade/variables';

  &__header {
    .title {
      color: $white !important;
    }
  }

  &__header-background {
    background-color: $aqua;
  }

  &__next-button {
    &.button {
      color: $navy;
    }
  }

  .MonetaryControlInput.control.has-icons-left .icon.is-left i {
    color: $dark-grey;
  }
}
