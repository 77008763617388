.InfluencerCardTabs {
  z-index: 0;

  &__tab-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 270px;
  }

  &--loading {
    align-items: center;
    justify-content: center;
  }
}
