.InfluencerEditableProfile {
  &__edit-mode-toggle__button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    .Icon {
      width: 3rem;
      height: 3rem;
    }
  }
}
