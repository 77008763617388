@import '../../css/themes/matchmade/variables';

.OnOffToggle {
  background-color: transparent !important;
  border-width: 0 !important;
  outline: 0 !important;

  > span {
    // Sadly material icons are just a tad off from the
    // label span's text
    padding-top: 0.1rem;
  }

  &.button:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }

  .material-icons {
    color: $aqua;
    margin-right: 0.5rem;
  }

  min-width: 3.2rem;
  min-height: 3.2rem;
  margin-right: 1rem;

  &.button.is-loading::after {
    border-bottom-color: $aqua;
    border-left-color: $aqua;
  }
}
