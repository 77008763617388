.CampaignDetails .Budget {
  .CentToDollarInput {
    .icon {
      i {
        font-size: 2.5rem;
      }
    }
    font-size: 2.3rem;
    &:placeholder-shown {
      font-size: 1rem;
    }
  }

  &__types {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .radio + .radio {
      margin-left: 0;
    }
  }

  &__input {
    position: relative;
  }

  &__budget-lock-input {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
  }

  &__budget-lock-container {
    display: flex;
    flex-direction: column;
  }

  &__lock-warning {
    padding: 2rem;
    white-space: normal;
  }
}
