@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/mixins';

.CampaignKpiChart {
  &__summary {
    .card {
      margin-right: 5px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &--invisible-card {
    box-shadow: none !important;
    border: none !important;
  }

  &__daterangepicker {
    flex: none; // Safari needs these
    flex-grow: 0 !important; // Safari needs these
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .ButtonWithLabel {
      margin-left: 2rem;
    }

    @include mobile() {
      & > * {
        margin-top: 0.3rem;
      }

      .button.is-large {
        font-size: 16px;
      }
    }
  }

  &__summary-metrics {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;
  }

  &__summary-text {
    font-size: 22px;

    strong {
      margin-right: 5px;
    }

    @include tablet() {
      span {
        display: block;
      }
    }
  }

  &__chart-selection {
    display: flex;
    align-items: center;
  }

  &__cumulative-checkbox {
    display: flex;
    align-items: center;
  }
}
