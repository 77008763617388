@import '../../../css/themes/matchmade/variables';
@import './common';
@import '~bulma/sass/utilities/mixins';

.LatestOfferTab {
  background-color: $white;
  height: 100%;

  display: flex;
  flex-direction: column;

  h2 {
    font-size: 0.875rem;
  }

  &__headline {
    text-align: center;

    .InfoTooltip {
      margin-left: 0.25rem;
    }

    strong {
      display: flex;
      align-items: center;
    }
  }

  &__prices .FormattedNumber {
    // Hard-code formatted number widths, so they don't
    // expand to the whole row (and tooltip is positioned in a sane way)
    width: 3rem;
  }

  &__big-number {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 0;
    @include until($mobile-break-point) {
      flex-direction: column;
      align-items: center;
    }

    h2 {
      font-size: 1.25rem;
      padding-top: 0.5rem;
      line-height: 100%;
    }
    small {
      line-height: 1.1;
      padding: 0 0 0.08rem 0.4rem;
    }
    border-bottom: 1px solid $semi-light-grey;
  }
  &__vertical {
    height: 5.7rem;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .LatestOfferTab__big-number {
      padding: 2.2rem 0;
    }

    small {
      line-height: 1.5;
      padding: 0;
    }
  }

  &__small-number {
    display: flex;
    &:not(:last-child) {
      border-bottom: 1px solid $semi-light-grey;
    }
    &:last-child {
      flex: 1;
    }
  }

  &__ecpm {
    .LatestOfferTab__title {
      justify-content: flex-end;
    }
    .LatestOfferTab__title,
    .LatestOfferTab__value {
      display: flex;
      align-items: center;
    }
  }

  &__title,
  &__value {
    padding: 0.45rem;
    flex: 1;
  }
  &__title {
    padding-right: 0.75rem;
    border-right: 1px solid $semi-light-grey;
    text-align: right;

    > p {
      font-weight: bold;
      font-size: 0.875rem;
    }

    > .Icon {
      font-size: 1rem;
    }
  }
  &__value {
    padding-left: 0.75rem;
  }

  &__cost {
    display: flex;
    align-items: center;
    small {
      color: $semi-dark-grey;
      margin-left: 1rem;

      > span {
        font-size: inherit;
        line-height: inherit;
      }
    }
    span {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }
}
