@import '../../../css/themes/matchmade/variables';
@import '~bulma/sass/utilities/mixins';

.ScreenshotsTab {
  background-color: white;
  height: 100%;
  padding-top: 2rem;

  &__no-screenshots {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include mobile() {
    .slick-arrow {
      visibility: hidden;
    }
  }
  .slick-initialized.slick-slider {
    height: 100%;
  }

  .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 22rem;
  }

  .slick-slide {
    object-fit: cover;
    margin: 1rem 0;
    overflow: hidden;
  }

  .slick-dots {
    top: -1.25rem;
    height: 2rem;

    @include mobile() {
      li {
        width: 10px; // Half of original width
      }
    }

    li.slick-active button:before {
      color: $aqua;
    }
  }

  .slick-prev {
    left: 1rem;
    right: auto;
  }
  .slick-next {
    right: 3.5rem;
    left: auto;
  }
  .slick-prev,
  .slick-next {
    z-index: 4;
    &.control {
      position: absolute;
    }
    &:before {
      display: none;
    }
  }

  .slick-list,
  .slick-track {
    height: 100%;
  }
}
