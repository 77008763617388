@import '~bulma/sass/utilities/mixins';

.CampaignInfoTab {
  @import '../../../css/themes/matchmade/variables';
  padding: 2rem 3rem;

  @include mobile() {
    padding: 1rem;
  }

  h2 {
    font-size: 2rem;
    padding-bottom: 6rem;
    line-height: 1.2;
  }

  &__table-row {
    display: flex;
    font-size: 1.25rem;
    line-height: 1.4;
    padding: 1rem 0;
  }

  &__table-left {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    text-align: right;
  }

  &__table-right {
    width: 60%;
    display: flex;
    flex-direction: column;

    &.image {
      width: 6.85rem;
      height: 6.85rem;
    }
  }

  &__attribution-icon {
    display: flex;
    align-items: center;
    margin-left: -0.5rem;
  }

  &__attribution-section {
    padding-top: 1rem;
  }

  &__countries {
    padding-top: 1rem;
  }

  &__campaign-name {
    color: $aqua;
    font-size: 2rem;
    line-height: 1.2;
    margin-top: -0.5rem;
  }

  &__platforms {
    display: flex;
    line-height: 0;
  }

  &__description {
    font-family: inherit;
    white-space: pre-line;
    line-height: 1.5;
    background-color: inherit;
    color: inherit;
    padding: 0;
    font-size: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-font-smoothing: inherit;
  }

  &__cpm-price {
    margin-left: 2.5rem;
  }

  .TabIcon {
    margin-top: -0.3rem;
  }

  &__promotion-info:not(:last-child) {
    padding-bottom: 2rem;
  }

  &__warning-block {
    color: white;
    margin-top: 1rem;
    background-color: $red;
    border-radius: $radius;
    padding: 1.5rem;

    & > strong {
      color: white;
    }
  }
}
