// This is intended to be imported by theme-specific
// styles inside '.my-root-theme-class' block

@import '~flag-icon-css/css/flag-icon.css';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~bulma/sass/utilities/mixins';

.root {
  // Make sure our child container has top and bottom padding
  // (and padding in general on mobile, since containers
  // don't have anything there)
  > div.container {
    width: 100%;
    @media screen and (min-width: $tablet) {
      padding: 3rem 0;
      @include touch() {
        padding: 3rem;
      }
    }
    @media screen and (max-width: $tablet) {
      padding: 1rem 0;
      @include touch() {
        padding: 1.5rem 1rem;
      }
    }
  }
}

// Action-entity icons
// Loosely based on https://github.com/FortAwesome/Font-Awesome/issues/5027
// <span class="fa-stack">
//   <i class="fa fa-user fa-stack-2x fa-stack__entity"></i>
//   <i class="fa fa-check fa-stack-1x fa-stack__action"></i>
// </span>
.fa-stack {
  &__action {
    font-size: 100%;
    margin: 15% 0 0 20%;
    text-shadow: -1px 0px 0 $background, 0px -1px 0 $background;
  }
}

// Make sure notifications (mostly warnings in our case)
// on mobile have some space between left and right level items
.notification.level.is-mobile .icon.level-left {
  margin-right: 5px;
}

.notification {
  @include mobile() {
    .icon {
      display: none;
    }

    .level-item {
      width: 100%;
    }
  }
}

// Assumes this is a child of flex
.responsive-truncate-contents {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card {
  // TODO: use variable for this color
  border: 1px solid #d3d6db;
}

// There is a PR for that, so we can get rid of the style below when
// https://github.com/jgthms/bulma/pull/413 will be merged
.image.is-circle img {
  border-radius: 290486px;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

// TODO Just use bulma's default is-paddingless
.no-padding {
  padding: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.is-vertically-aligned {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.is-tablet {
    // Flex-start on mobile, otherwise -- center
    @include tablet() {
      justify-content: center;
    }
    justify-content: flex-start;
  }
}

.is-transparent {
  background: transparent !important;
}

.notification {
  &.is-warning {
    a {
      color: $navy;
      text-decoration: underline;
    }
  }
}

.is-truncated {
  flex: 1;
  &,
  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.is-invisible {
  visibility: hidden;
}

// Additions to bulma image helper to work with Steam (header) images
// https://github.com/jgthms/bulma/blob/master/sass/elements/image.sass
// Steam images are usually of size (and multiples of size) 460x215

.image {
  &.is-52x24 {
    width: 52px;
    height: 24px;
  }
  &.is-103x48 {
    width: 103px;
    height: 48px;
  }
  &.is-274x128 {
    width: 274px;
    height: 128px;
  }
  &.is-548x256 {
    width: 548px;
    height: 256px;
  }
}

.tabs {
  li {
    &.is-disabled {
      opacity: 0.4;
    }
  }
}

// Since *almost* all Griddles that we use use sortable headers make them look clickable
.griddle {
  .table thead td,
  .table thead th {
    cursor: pointer;
    white-space: nowrap;
  }
  .table tfoot td,
  .table tfoot th {
    cursor: pointer;
    white-space: nowrap;
  }
}

.section {
  &.margin-bottom {
    margin-bottom: 1.5rem;
  }
}

a:disabled {
  cursor: not-allowed;
}

// Bulma's dropdown arrows, but filled versions
.select:not(.is-multiple):not(.is-loading),
.navbar-link:not(.is-arrowless) {
  &::after {
    // Override downward tick arrow to be full arrow
    margin-top: -0.375em;
    right: 1.125em;
    border-style: solid;
    width: 0;
    height: 0;
    transform: none;
    border-bottom: none;
    border-width: 0.4rem;
    top: 55%;
    border-radius: 0;
  }
  &::after,
  &:hover::after {
    border-left-color: transparent;
    border-right-color: transparent;
  }
}

// Use this on button element to make it look like link element
// https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md#case-i-understand-the-previous-cases-but-still-need-an-element-resembling-a-link-that-is-purely-clickable
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: $aqua;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
  outline: none;
}
