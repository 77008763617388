@import '../../../../css/themes/matchmade/variables';

.AdminTab__commission-rate-tooltip {
  width: 13rem;
  padding: 0.2rem 0.5rem;
  text-align: center;
}

.AdminTab {
  background-color: $white;
  height: 100%;
  padding-top: 1rem;

  .field {
    .Button {
      button {
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
      }
    }
  }

  .CurrencyInput {
    input {
      border-radius: $radius;
    }
  }

  .DateOffer.field:not(:last-child) {
    margin-bottom: 0;

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__suggestion-table {
    margin-bottom: 1rem;
  }

  &__accept-as {
    .Avatar {
      margin-left: 0.25rem;
    }
  }

  &__edit-icon-cell {
    width: 2rem;
  }

  table.table {
    td {
      vertical-align: middle;
    }
  }

  &__influencer-value-cell,
  &__publisher-value-cell {
    width: 30%;
  }

  &__edit-icon-cell {
    width: 18%;

    &--disabled {
      i {
        color: $dark-grey;
      }

      a {
        cursor: wait;
      }
    }
  }

  &__editing-icons {
    display: flex;

    .CloseIcon i {
      color: $semi-dark-grey;
    }
  }

  input.AdminTab__commission-input {
    height: 100%;
    background-color: $light-grey;
  }
}
