.matchmade .TopNavigationBar {
  @import '../../css/themes/matchmade/_variables';
  background-color: $white;

  .headroom {
    border-bottom-color: $semi-dark-grey;
    background-color: $white;
  }
  .navbar-link,
  .navbar-menu > .navbar-item {
    font-size: 1rem; // avoiding differences in sizes between tags (div, button, etc...)
  }
  .navbar-link:not(.is-arrowless)::after {
    border-top-color: $aqua;
  }
  .navbar-item .navbar-dropdown {
    border-top-color: $semi-dark-grey;
  }

  .notification a:not(.button):not(.dropdown-item) {
    text-decoration: none;
    color: $aqua;
  }

  &__avatar img {
    border-radius: $radius;
  }
}

.Layout--create-campaign .TopNavigationBar {
  .headroom {
    border-bottom-color: transparent; // we always have top header in create campaign
  }
}

.Layout--edit-campaign .TopNavigationBar {
  .headroom {
    border-bottom-color: transparent; // we always have top header in edit campaign
  }
}

.TopNavigationBar {
  @import '../../css/themes/matchmade/_variables';

  &__avatar,
  &__name {
    margin-right: 1.25rem;
  }

  .headroom {
    z-index: 1300 !important; // More than Spinner in 'cover' mode
    border-bottom: thin solid;
  }

  .notification {
    // We display full-width notifications in navbar, they don't need rounded corners
    border-radius: 0;
  }

  .navbar-brand figure.image {
    min-width: 2.75rem;

    img {
      max-height: 2.75rem;
      max-width: 2.75rem;
    }
  }

  .navbar-link:not(.is-arrowless)::after {
    // Override downward tick arrow to be full arrow
    margin-top: -0.375em;
    right: 1.125em;
    border-style: solid;
    width: 0;
    height: 0;
    border-left-color: transparent;
    border-right-color: transparent;
    transform: none;
    border-bottom: none;
    border-width: 0.4rem;
    top: 55%;
    border-radius: 0;
  }

  // Would be awesome to use .link-button here, but it resets
  // margins & paddings, which is not what we want in this case
  button.navbar-link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
  }

  // Admins have lots of links so they might overlap with the .nav-right, so
  // let nav-left grow more
  .nav-left {
    flex-grow: 3;
  }
  .chat-dropdown.is-right {
    right: -12rem;
  }
  .chat-burger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
  }
  .chat-burger + .navbar-burger {
    margin: 0;
  }
  .navbar-item {
    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
    .chat-item {
      width: 30rem;
      overflow: hidden;
      padding: 0;
      position: relative;
      left: 0;
    }

    .navbar-dropdown {
      border-top: thin solid;
      button,
      a {
        justify-content: flex-start;
      }
    }
  }
}
