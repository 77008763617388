@import '../../../css/themes/matchmade/variables';
@import './common';

.AdminApproveDealTab {
  background-color: $white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-around;
  text-align: center;

  .MissingAmount {
    padding: 1.5rem;
  }

  .ApproveDeal {
    padding: 1.5rem;

    &__prices {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      &-item {
        flex-grow: 1;

        &-label {
          font-weight: bold;
        }
      }

      .material-icons {
        font-size: inherit;
      }
    }
  }

  .DealView {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .Payout {
      width: 100%;
      padding: 1.5rem;
      padding-bottom: 0.5rem;
      border-bottom: thin solid $semi-light-grey;
    }

    .InvoiceItems {
      width: 100%;
      padding: 1.5rem;
      padding-bottom: 0.5rem;
    }
  }
}
