@import '../../css/themes/matchmade/variables';

.CampaignAttributionSetup {
  &.box {
    margin-bottom: 0;
    padding: 2rem;
  }

  &__flexdiv {
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }

  &__pad-for-publisher-view.box {
    padding-left: 2rem;
    margin-bottom: 2rem;
  }

  &__button.button {
    flex: 1;
    width: 100%;
  }

  &__onOffToggler {
    min-width: 20rem;
    min-height: 20rem;
  }

  &__left-div {
    flex: 1;
    margin-right: 2rem;
  }

  &__checkbox-div {
    width: 100%;
    flex: 1;
  }

  &__prompt-attribution-icon-div {
    flex: 1;
  }

  &__links-div {
    flex: 1;
  }

  &__save-button-div {
    display: flex;
  }

  &__test-links {
    flex: 1;
    margin-right: 4rem;
  }

  &__selection {
    margin-bottom: 2rem;
    display: block;
    margin-right: 2.43rem;
    flex: 1;
    min-width: 20rem;
    background-color: $light-grey;
    max-height: 3rem;

    &.Select {
      border: none;

      .Select-menu-outer {
        border: none;
      }

      .Select-placeholder {
        background-color: $light-grey;
        line-height: 3rem;
      }

      .Select-control {
        border: none;
        height: 3.2rem;
        background-color: $light-grey;
        margin-right: 2rem;
      }

      .Select-value {
        background-color: $light-grey;
      }

      .Select-option {
        background-color: $light-grey;
      }

      .Select-option.is-focused {
        background-color: $middle-grey;
      }

      .Select-value-label {
        line-height: 3.2rem;
      }
    }

    &__cpi-error {
      &.Select {
        .Select-control {
          border: 0.1rem solid red;
        }
      }
    }
  }

  .checkbox-span {
    height: 3.2rem;
    line-height: 3.2rem;
  }

  &__input-span {
    display: block;
  }

  &__attribution-title {
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  &__attribution-title-tooltip {
    display: flex;

    .InfoTooltip {
      margin-left: 0.5rem;
    }
  }
}
