.SessionExpiredConfirmation {
  @import './BasicModal.scss';

  .modal-card {
    width: 40rem;
    text-align: center;
  }

  .modal-card-head {
    flex-direction: column;
    padding: 2rem;

    h3 {
      margin-bottom: 0.4rem;
    }
  }

  button {
    border-radius: 0;
  }
}
