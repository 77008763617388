.EmailCopyIcon {
  margin-right: 0.25rem;

  &__tooltip {
    text-align: center;
    white-space: pre;
    display: flex;
  }

  & i.material-icons {
    margin-top: 0.3rem; // why you no align properly :/
    font-size: 1.4rem;
  }
}

.matchmade .EmailCopyIcon {
  @import '../../css/themes/matchmade/variables';

  & i.material-icons {
    color: $semi-light-grey;
    &:hover {
      color: $aqua;
    }
  }
}
