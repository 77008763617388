@import '../../campaignCreation/formVariables.scss';

.PublisherCampaigns {
  margin-bottom: 1.5rem;

  &__create-new-campaign-button.button {
    padding: 0 2rem;
  }

  .button {
    border: none;
    flex: 1;
    font-weight: 700;
    margin: 0 0.5rem 1.5rem;
    height: $height;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &__modal {
    .buttons > span:first-child {
      margin-bottom: 2rem;
    }
  }

  table {
    td {
      .Icon {
        display: block;
      }
    }
  }
}
