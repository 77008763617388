@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/mixins';

.CampaignViewForInfluencer {
  // TODO right now this looks shitty on mobile; everything is quite tight, no space whatsoever
  //      we probably should wrap the whole view into section, but that changes background to white
  //      check with biz-team how this should look, maybe have to do the same for
  //      CampaignViewForPublisher.
  //      Maybe do as part of https://trello.com/c/3vEoyxuQ/485-campaign-page-usability-improvements
  @include touch() {
    &__controls {
      padding: 2rem;
    }
  }

  .CampaignGameDownloadIFramePreview {
    margin-top: 30px;
  }

  .CpiInputWithMinGuarantee {
    margin-top: 1rem;
  }
}
