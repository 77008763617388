@import '../../../css/themes/matchmade/variables';

.LastDeals {
  background-color: $white;
  height: 100%;
  padding: 0.6rem 0.5rem;
  display: flex;
  overflow-x: scroll;

  &.no-deals {
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    text-align: center;
  }

  &__title,
  &__value {
    border-bottom: 1px solid $semi-light-grey;
    margin: 0.05rem 0;
  }

  &__title {
    font-size: 0.875rem;
    min-width: 5rem;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__campaign {
    a {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  &__value {
    font-size: 0.87rem;
    white-space: nowrap;

    &:last-child {
      border-bottom: 0;
    }
  }

  .column {
    padding: 0;
  }
}
