.PublisherTable {
  @import '../../css/themes/matchmade/variables.scss';
  &__team-verified {
    color: $navy;
    vertical-align: baseline;
    margin-left: 0.35rem;
    margin-bottom: 0.3rem;
  }

  &__actions {
    display: flex;
    justify-content: space-evenly;
  }
}
