.HeaderNotification {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  svg {
    width: 3.5rem;
    height: 3.5rem;
  }
}

.matchmade .HeaderNotification {
  @import '../../css/themes/matchmade/variables';

  color: $white;
}
