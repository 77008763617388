.PromoCodes {
  &.box {
    padding: 2rem;
  }

  .stats-elem {
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;

    .num {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    .label {
      opacity: 0.8;
    }
  }
}
