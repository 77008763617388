@import '../../../css/themes/matchmade/variables';

.CentToDollarInput {
  &.input {
    .control.has-icons-left &:not(:only-child) {
      padding-left: 1.9rem;
    }
  }

  & + span .material-icons {
    font-size: inherit;
    color: $dark-grey;
  }
}
