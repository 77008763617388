.Pagination {
  &__page-limit {
    order: 1;
    font-size: 0.875rem;

    .select {
      height: 2.285em;
    }
  }

  .pagination {
    justify-content: flex-start;

    // Can't use link-button, since we need those
    // margins & paddings
    button {
      background-color: transparent;
      cursor: pointer;
      display: inline;
    }

    .pagination-previous {
      order: 2;
    }

    .pagination-list {
      order: 3;
      flex-grow: 0;
    }

    .pagination-next {
      order: 4;
    }
  }
}
