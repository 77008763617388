@import '../../components/campaignCreation/formVariables.scss';

.MultiValueSelector {
  $menuHeight: 25rem;

  &__selected-values {
    padding: 0.7rem;
  }

  &__selected {
    cursor: pointer;
    position: relative;
    padding-right: 1.3rem !important;
    height: 1.5rem !important;

    margin-right: 0.2rem;
    margin-bottom: 0.2rem;

    &:after {
      position: absolute;
      font-family: 'Material Icons';
      content: 'close';
      right: 0.3rem;
    }
  }

  &__selected-option-name {
    position: absolute;
    max-width: 90%;
    &:not(:last-of-type) {
      margin-right: 0.75rem;
      &:after {
        content: ', ';
      }
    }
  }

  &__rest-of-selected {
    display: inline-block;
  }

  &__custom-label {
    max-width: 10rem;
  }

  &__add-label {
    padding: 8px 10px; // whatever value Select has for the options
    font-weight: 700;
  }

  .CustomSelect {
    &.is-focused .MultiValueSelector__selected-option-name {
      display: none;
    }
    .Select-menu-outer {
      min-height: $menuHeight;
    }

    .Select-menu {
      max-height: $menuHeight;
    }

    &.is-focused .Select-control:before {
      content: 'search';
      font-family: 'Material Icons';
      display: flex;
      align-items: center;
      margin-right: 0.35rem;
      margin-bottom: 0.2rem;
      font-size: 1.21rem;
    }

    &.is-open .Select-control .Select-multi-value-wrapper .Select-input input {
      line-height: 2;
      padding: 0;
    }

    &.has-value {
      .Select-control {
        .Select-multi-value-wrapper {
          position: relative;
          .Select-input {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}

.matchmade .MultiValueSelector {
  @import '../../css/themes/matchmade/variables';

  &__selected {
    &:after {
      color: $aqua;
    }
  }

  .CustomSelect {
    .Select-control:before {
      color: $semi-dark-grey;
    }
    .Select-multi-value-wrapper {
      color: $navy;

      .Select-input {
        input {
          color: $navy;
        }
      }
    }
  }

  &__add-label {
    color: $dark-grey;
  }
}
