.AppsFlyer {
  &__info-wrapper {
    display: flex;
    & > * {
      padding: 0.5rem;
    }
  }

  &__info-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    img {
      max-width: 14rem;
      padding-top: 0.5rem;
    }
  }

  &__info-line {
    margin-bottom: 0.3rem;
  }

  &__info-icon {
    padding-left: 0;
  }
}
