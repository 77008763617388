.SearchInfluencerCard {
  @import '../../../css/themes/matchmade/variables';

  .InfluencerCardTabs__tab-content {
    flex-basis: 300px;
  }

  &__overview {
    display: flex;
    flex-direction: column;
    flex: 1;

    .ChannelCountry {
      flex: 1;
    }
  }

  span.iconHolder {
    width: 1.5rem;
    vertical-align: middle;
    margin-left: 0.5rem;
    display: inline-block;
  }

  .CollectionSelectorTrigger {
    margin-top: 1rem;
    width: 100%;

    button.button {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
    }
  }
}
