.Spinner {
  &--fullWidth {
    min-height: 75vh;
    flex: 1;
    text-align: center;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--cover {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1200;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  // This assumes that parent is position: relative
  &--overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.8;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__children {
    margin-top: 10px;
  }

  &--cover &__content {
    color: black;
    background-color: transparent;
    padding: 10px 12px 10px 10px;
    z-index: 9001;
    border-radius: 0;
    display: flex;
  }

  &__background {
    background-color: gray;
    opacity: 0.5;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
