.EditableHeader {
  input {
    border: 0;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__button.link-button {
    margin-bottom: 1.5rem;
    margin-left: 0.5rem;
  }
}
