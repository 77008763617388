@import '../../../css/themes/matchmade/variables';

.DynamicSelector {
  .Select-option {
    background-color: $light-grey;
    padding: 0;
  }

  .Select-option.is-focused .Option {
    background-color: $middle-grey;
    cursor: pointer;
  }
  &.Select {
    border-radius: $control-radius; // This is not the same as react-select, hence custom border-radius :(
    background-color: $light-grey;

    &.is-focused > .Select-control {
      background-color: $light-grey;
    }

    .Select-loading {
      border-right-color: $aqua;
    }

    &.is-open > .Select-control {
      border-radius: $control-radius; // This is not the same as react-select, hence custom border-radius :(
      .Select-arrow {
        border: 0.125rem solid $aqua;
        border-right: 0;
        border-top: 0;
      }
    }
    .Select-control {
      border-radius: $control-radius; // This is not the same as react-select, hence custom border-radius :(
      .Select-arrow {
        border: 0.125rem solid $aqua;
        border-right: 0;
        border-top: 0;
      }
    }

    .Select-menu-outer {
      background-color: $light-grey;

      .Select-menu {
        border-bottom-left-radius: $control-radius;
        border-bottom-right-radius: $control-radius;
      }
    }
  }

  .Option {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 2rem;
  }

  .Option__label {
    flex: 1 1 auto;
  }

  .Option__aside {
    flex: 0 0 auto;
    max-width: 15rem;
  }
  .countryOptionFlag {
    width: 30px;
    flex: 0 0 auto;
  }

  &.Select {
    border-width: 0;
    padding: 1rem;
    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .Select-input {
      margin-left: 1rem;
      padding-left: 0;
      padding-right: 0;
    }

    &.is-focused:not(.is-open) > .Select-control {
      border: none;
      box-shadow: none;
    }

    &.is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.is-disabled > .Select-control {
      background-color: transparent;
    }

    &.is-open > .Select-control {
      background: transparent;
      border: none;

      // flip the arrow so its pointing up when the menu is open
      .Select-arrow {
        display: block;
        height: 0.5rem;
        pointer-events: none;
        position: absolute;
        transform: rotate(135deg);
        transform-origin: center;
        width: 0.5rem;
        margin-top: -0.375rem;
        right: 1.125rem;
        top: 50%;
        z-index: 4;
      }
    }

    .Select-control {
      background-color: transparent;
      border-width: 0;
      &:hover {
        box-shadow: none;
      }

      .Select-arrow {
        display: block;
        height: 0.5rem;
        pointer-events: none;
        position: absolute;
        transform: rotate(-45deg);
        transform-origin: center;
        width: 0.5rem;
        margin-top: -0.375rem;
        right: 1.125rem;
        top: 50%;
        z-index: 4;
      }
    }
    .Select-menu-outer {
      border: none;
      box-shadow: none;
      box-sizing: border-box;
      margin-top: -1px;
      max-height: 200px;
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      z-index: 1000;
    }
    .VirtualSelectGrid {
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none !important;
      &::-webkit-scrollbar {
        width: 0; /* remove scrollbar space */
        background: transparent; /* optional: just make scrollbar invisible */
      }
    }
    .Select-noresults {
      padding-left: 2rem;
    }
  }
}
