.AttributionInfo {
  &__description {
    display: flex;
    align-items: center;

    .material-icons {
      margin-right: 0.5rem;
    }
  }
}
