.matchmade .FloatingFooter {
  @import '~bulma/sass/utilities/mixins';
  @import '../../css/themes/matchmade/variables';

  &__content,
  &__spacer {
    background: $white;
    border-top: thin solid $semi-dark-grey;
  }
}

.FloatingFooter {
  &__content,
  &__spacer {
    min-height: 70px; // Just about the size of a large button
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    > * {
      margin: 10px;
    }
  }

  &__content {
    // stick it to the bottom
    position: fixed;
  }
  &__spacer {
    // We need some space before footer, but we don't need it to be visible or receive any events
    visibility: hidden;
  }
}
