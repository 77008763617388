.CollectionView {
  &__relatedCampaigns {
    margin-bottom: 1rem;

    &Title {
      margin-bottom: 0.75rem;
    }

    .action_button {
      margin-left: 0.75rem;
    }
  }

  &__relatedCampaignsTitle {
    display: flex;
    align-items: center;

    .InfoTooltip {
      margin-left: 0.5rem;
    }
  }

  &__influencers {
    margin-top: 2rem;
    margin-bottom: 10rem;
  }

  &__mturkResult {
    margin-top: 1rem;
    max-height: 10rem;
    overflow-y: scroll;
  }
}
