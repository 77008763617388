.CreatePayoutForm {
  .metadata__field {
    .input {
      max-width: 45%;
    }
    .button {
      max-width: 10%;
    }
  }
  .MuiAlert-message > p > a {
    color: white;
  }
}
