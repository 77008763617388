.FollowedInfluencersTable {
  &__channel-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__channel-link,
  &__contact-info {
    max-width: 200px;
  }
}
