.AdminDashboardImportInfluencer {
  @import '../../css/themes/matchmade/_variables';
  .select:not(.is-multiple):not(.is-loading)::after {
    display: none;
  }

  &__result-section {
    .success {
      color: $green;
    }
    .not-found-container {
      color: $red;
      margin: 1rem 0;
    }
  }

  &__add-to-collection-section {
    margin: 1rem 0;

    .mdCheckIcon {
      font-size: inherit;

      .material-icons {
        font-size: 1rem;
      }

      & + span {
        display: inline-block;
        height: 1.5rem;
        vertical-align: text-bottom;
      }
    }
  }

  &__ig-card {
    width: 30rem;
  }
}
