.Glossary {
  @import '~bulma/sass/utilities/mixins';
  @import '../../../css/themes/matchmade/variables';
  padding: 2rem 3rem;
  font-size: 1.25rem;
  line-height: 1.75rem;

  @include mobile() {
    padding: 2rem 1rem;
  }

  h2 {
    font-size: 2rem;
    padding-bottom: 6rem;
  }

  &__table {
    display: flex;
    flex-wrap: wrap;
  }

  &__table-item {
    min-width: 50%;
    max-width: 50%;
    flex: 1;
    margin-bottom: 4rem;
    padding-right: 2rem;

    @include mobile() {
      max-width: 100%;
      min-width: 100%;
    }

    h4 strong {
      color: $aqua;
    }
  }

  figure {
    height: 4rem;
    margin-bottom: 2rem;
  }

  &__promotion-types {
    p {
      margin-bottom: 1rem;
    }
  }
}
