@import '../../css/themes/matchmade/variables';

.NotificationBanner {
  &.is-default {
    background-color: $light-aqua;
  }
  &.is-warning {
    background-color: $warning;
  }

  .MuiAlert-root {
    background-color: transparent;
    font-size: 1rem;
  }
  .MuiAlert-icon svg {
    fill: $text;
  }
}
