@import '../../css/themes/matchmade/variables';

.Table {
  border-radius: $radius;
  &__search {
    &,
    .SearchInput {
      height: 100%;
    }
  }
  &__pagination {
    &--top {
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
      .Pagination {
        align-self: flex-end;
      }
    }

    &--bottom {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  &.table {
    margin-bottom: 0;

    .Column {
      &__sort-icon {
        margin-left: 4px;
      }
      &--sortable {
        cursor: pointer;
      }
    }

    thead {
      th {
        &.Column {
          vertical-align: middle;
        }

        &.GroupedColumn {
          border-bottom: none;
          text-align: center;
        }
      }
    }

    tbody {
      td {
        vertical-align: middle;
      }
    }

    tfoot {
      th {
        &.Column {
          vertical-align: middle;
        }

        &.GroupedColumn {
          border-top: none;
          text-align: center;
        }
      }
    }
  }
}
