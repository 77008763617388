@import '../../../css/themes/matchmade/variables';

.TabsContainer {
  align-items: center;
  justify-content: center;
  border-bottom: thin solid $dark-grey;

  ul.tabs {
    max-width: 100%;
    border: 0;

    justify-content: center;

    li {
      flex: 1 1;
      cursor: pointer;
      min-width: 4rem;
      max-width: fit-content;

      transition-property: flex-grow;
      transition-duration: 250ms;
      transition-timing-function: ease-in-out;

      &:hover,
      &:focus {
        flex-grow: 15;

        .label {
          background-color: darken($white, 25%);
        }
      }

      > a {
        padding: 1rem 1em;
        background: $semi-light-grey;
        height: 4rem;
        justify-content: flex-start;
      }

      .DynamicCollectionIcon,
      .TabIcon {
        margin-right: 0.5rem;
        min-width: 1.5rem;
        min-height: 1.5rem;
      }

      &.is-active .label {
        border-color: transparent;
        background-color: $white;
      }

      &.is-admin .label {
        background-color: $orange;

        &:hover {
          background-color: darken($orange, 10%);
        }
      }
    }

    a {
      margin: 0 0.1rem;
    }

    .tag.is-rounded {
      background-color: transparent;
      color: $dark-grey;
    }

    &.is-boxed {
      margin-bottom: 0;

      a:hover {
        border-bottom-color: transparent;
      }
    }
  }

  .CollectionSelectorTrigger {
    color: $aqua;
    height: 100%;
    display: flex;
    align-items: center;

    &__button {
      border: 0;
      background-color: transparent;
      margin: 0 0.5rem;
      &:hover {
        background-color: transparent;
        opacity: 0.5;
      }
    }
  }
}
