@import '../../css/themes/matchmade/variables';
.NewChatMessage {
  form {
    flex: 1;
  }

  &__undo-block {
    margin-right: 1rem;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0.5rem;

    small {
      margin-right: 1.5rem;
    }

    .control.Button {
      flex: none;

      button {
        width: 10rem;
      }
    }
  }

  .textarea {
    // Walsheim font default to something funny, so
    // our tracking links _sometimes_ display
    // "multiplication" character instead of "x"
    // So force it to use no font variations for links
    font-variant: none;
    background-color: inherit;
    border: 0;
    margin-bottom: 0.5rem;

    &[disabled] {
      background-color: inherit;
    }
  }

  &__characters-counter {
    font-size: 0.7rem;
    margin: 0 0.5rem 0.5rem 0;
    color: $dark-grey;
    width: 100%;
    text-align: right;
  }
}
